import React, { useState, useEffect } from 'react';
import { Card, Divider } from 'antd';
import { ButtonSizes, PrimaryButtonComponent, ButtonStyles, HighlightMessagesComponent, MessageType } from "@betterhome/components";
import {CheckCircle } from "@phosphor-icons/react";
import Location from '../../../assets/Icons/location-map.svg';
import Passport from '../../../assets/Icons/passport.svg';
import CheckSolid from '../../../assets/Icons/check-circle-solid.svg';
import LocationDisabled from '../../../assets/Icons/location-house-disabled.svg';
import PassportDisabled from '../../../assets/Icons/passport-disabled.svg';
import circle from '../../../assets/Icons/circle.svg';
import Warning from '../../../assets/Icons/warning.svg';
import ModalTemplate from '../../../Components/Templpate/ModalTemplateComponent';
import DefaultTemplateBeforeLoginComponent from '../../../Components/Templpate/DefaultTemplate';
import { useLocation, useNavigate } from 'react-router-dom';
import {useLoading} from "../../../services/Utils/LoadingContext";
import Text from "../Constants.json";
import {getCustomerRequestByCustometId,updateCustomerRequestStatusAPI, verifyAddress} from '../../../services/apiService/api';

const RequestComponent = () => {
  interface VerificationStepProps {
    icon: React.ReactNode;
    title: string;
    suffixicon?: React.ReactNode;
  }

  const RequestComponentText = Text.RequestComponent;


  const VerificationStep: React.FC<VerificationStepProps> = ({ icon, title, suffixicon }) => {

    return (
      <div className="verification-step" style={{ padding: '0px 0px', justifyContent: 'space-between' }}>
        <div className="step-icon">{icon}</div>
        <div className="BaseRegular">{title}</div>
        <div className="suffix-icon">{suffixicon}</div>
      </div>
    );
  };
  const navigate = useNavigate()
  const { isLoading, setLoading } = useLoading();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [showIdv, setShowIdv] = useState(false);
  const [showPoa, setShowPoa] = useState(false);
  const location = useLocation();
  const [requestDataFromId ,setRequestDataFromId] = useState<any>();
  const [requestFeatureData,setRequestFeatureData] = useState<any>();
  const [verifyAddressReqObj, setVerifyAddressReqObj] = useState({});
  const requestId = sessionStorage.getItem('requestId');
  const storedProfile = sessionStorage.getItem("userDataSession");
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  const searchParams = new URLSearchParams(location.search);
  const reqId = searchParams.get('id');
  let fccNumber = "";
    useEffect(() => {
      const fetchData = async () => {
        if (reqId) {
          try {
            const res = await getCustomerRequestByCustometId(reqId);
            if('data' in res){
              setRequestDataFromId(res.data);
            }
          } catch (error) {
            // Handle error
          }
        }
      };
      fetchData();
    }, []);

    useEffect(() => {
        if (storedProfile) {
          const userDataObj = JSON.parse(storedProfile);
            let modifiedPhoneNumber = userDataObj.customerMobile;
            if (modifiedPhoneNumber.startsWith("27")) {
                modifiedPhoneNumber = "0" + modifiedPhoneNumber.slice(2);
            }
            const verifyAddress = {
            "idNumber": userDataObj.SaId,
            "firstName": userDataObj.givenName,
            "surname": userDataObj.familyName,
            "phoneNumber": modifiedPhoneNumber,
            "email": userDataObj.customerEmail,
            "address": ""
            };
            setVerifyAddressReqObj(verifyAddress);
      };
    }, []);


    const postAddress = async () => {
      try {
        if(verifyAddressReqObj && Object.keys(verifyAddressReqObj).length > 0){
          const res = await verifyAddress(verifyAddressReqObj);
          return  {
            province: res.resdata.province,
            addressLine1: res.resdata.residentialAddress1Line1 + res.resdata.residentialAddress1Line2,
            addressLine2: res.resdata.residentialAddress1Line3,
            suburb: res.resdata.residentialAddress1Line4,
            postalCode: res.resdata.residentialAddress1PostalCode
        };
          // Check if response is successful and contains data
        }
      } catch (error) {}
    };

    useEffect(() => {
      const requestIdData = sessionStorage.getItem("requestIdData");
      if (requestIdData) {
          const requestDataObj = JSON.parse(requestIdData);
          setRequestFeatureData(requestDataObj);
      }
  },[]);


  let requestorName: string = '';
  let requestorImage: string = ''; 
  let companyLogo: string = '';
  // Access the "kyc" and "addressVerify" values
  if (requestFeatureData) {
    requestorName = requestFeatureData.requestors?.firstName || requestFeatureData?.requestorInfo.firstName  || "";
    requestorImage = requestFeatureData.requestors?.profileImage || requestFeatureData?.requestorInfo.profileImage || "";
    companyLogo = requestFeatureData.requestors?.company.logo || requestFeatureData?.requestorInfo.company.logo || "";
    fccNumber = requestFeatureData?.requestorInfo.ffcNumber || ""
  } else {
    requestorName = requestDataFromId?.requestorInfo.firstName || ""
    requestorImage = requestDataFromId?.requestorInfo.profileImage || "";
    companyLogo = requestDataFromId?.requestorInfo.company.logo || "";
    fccNumber = requestDataFromId?.requestorInfo.ffcNumber || ""
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDecline = async () => {
    // setIsSlideoverVisible(false);
    const statusUpdate = await updateCustomerRequestStatusAPI('3',requestId)
    navigate("/dashboard")
   };
  
  useEffect(() => {
    if(requestFeatureData){
      let isComplete = 
        requestFeatureData.requestedFeature.length > 0 &&
        requestFeatureData.requestedFeature.every((feature: any) => feature.requestedFeatureStatus === 4);
      setIsComplete(isComplete);
      const showIdv = requestFeatureData.requestedFeature.some((feature:any) => feature.requestedFeatureType === 1);
      setShowIdv(showIdv)
      const showPoa = requestFeatureData.requestedFeature.some((feature:any) => feature.requestedFeatureType === 2);
      setShowPoa(showPoa)
    }
  }, [requestFeatureData]);
  
  const requestStatus = async (isIdvComplete:boolean,isPoaComplete:boolean) => {
    setLoading(true)
    if((isIdvComplete && isPoaComplete) || (!showIdv && isPoaComplete) || (!showPoa && isIdvComplete)){
      return "/loading"
    }else if((!isIdvComplete && !isPoaComplete && showIdv && showPoa )|| (!isIdvComplete  && showIdv && !showPoa)){
      return "/idv-start"
    }else if((isIdvComplete && !isPoaComplete && showPoa && showIdv) || (!isPoaComplete && showPoa && !showIdv)){
      const addressObj = await postAddress();
      if (addressObj) {
        sessionStorage.setItem("userAddress",JSON.stringify(addressObj))
        const allValuesEmpty = Object.values(addressObj).every(value => value === "");
        const result = allValuesEmpty ? "/poa-update" : "/poa-start";
        return result
      }else{
        return "/poa-update"
      }
    }
  }; 

  const handleBtnClick = async () => {
    let path = ""; // Set a default path or a fallback path
    let isIdvComplete = false;
    let isPoaComplete = false;

    if (isComplete) {
        path = (await requestStatus(isComplete, isComplete)) || path;
    } else {
        for (const feature of requestFeatureData.requestedFeature) {
            if (feature.requestedFeatureType === 1) {
                isIdvComplete = feature.requestedFeatureStatus === 4;
                path = (await requestStatus(isIdvComplete, isPoaComplete)) || path;
            } else if (feature.requestedFeatureType === 2) {
                isPoaComplete = feature.requestedFeatureStatus === 4;
                path = (await requestStatus(isIdvComplete, isPoaComplete)) || path;
            }
            //to do add pop up
        }
    }
    setLoading(false)
    navigate(path, { state: { from: "/register-component" } });
};
  // Helper function to get VerificationStep based on feature type
  const getVerificationStep = (type: any, feature: any) => {
    switch (type) {
      case 1:
        const RFIDVstatus = feature.requestedFeatureStatus == 4
        return (
          <VerificationStep
            icon={RFIDVstatus ? <img src={Passport} alt="Passport" /> : <img src={PassportDisabled} alt="Passport" />}
            title="Identity Verification"
            suffixicon={RFIDVstatus ? <img src={CheckSolid} alt="CheckSolid" /> : <img src={circle} alt="Circle" />}
          />
        );
      case 2:
        const RFPOAstatus = feature.requestedFeatureStatus == 4
        return (
          <VerificationStep
            icon={RFPOAstatus ? <img src={Location} alt="Location" /> : <img src={LocationDisabled} alt="Location" />}
            title="Proof of Residence"
            suffixicon={RFPOAstatus ? <img src={CheckSolid} alt="CheckSolid" /> : <img src={circle} alt="Circle" />}
          />
        );
      case 3:
        const RFAMLstatus = feature.requestedFeatureStatus == 4
        return (
          <VerificationStep
            icon={RFAMLstatus ? <img src={Location} alt="Other" /> : <img src={LocationDisabled} alt="Other" />}
            title="AML"
            suffixicon={RFAMLstatus ? <img src={CheckSolid} alt="CheckSolid" /> : <img src={circle} alt="Circle" />}
          />
        );
      default:
        return null;
    }
  };

  return (
    <DefaultTemplateBeforeLoginComponent
      isLoginProps={isAuthenticated?isAuthenticated : !isAuthenticated}
      isRegisterProps={isAuthenticated}
    >
      <Card className="page-card centered-content">
        <div className='profile-container mb-m'>
          {requestorImage && <img src={requestorImage} className='agent-profile-image' alt="Agent Image" />}
          {companyLogo && <img src={companyLogo} className={requestorImage?'agent-agency-logo':'agent-agency-logo-single'} alt="Company Logo" />}
        </div>
        <div className="verification-process">
          <h4 className='Text-Primary'>{requestorName}</h4>
          <h5 className='SmallRegular Text-Subtitle mt-xxs mb-xxs'></h5>
          {fccNumber && <h6 className='TinyRegular Text-Subtitle'>{RequestComponentText.subTitle2} {fccNumber}</h6>}
          {!isComplete && <div className='verification-contents'>
            <h4 className='BaseRegular vert-spacing-xl'>{requestorName} {RequestComponentText.subTitle1}</h4>
          </div>}
          {isComplete && <div className='mt-xl mb-xl'>
            <HighlightMessagesComponent
              Title=""
              Textmsg= {`${RequestComponentText.subTitle6} ${requestorName}?`}
              messageType={MessageType.success}
              closeIcon={null}
              customicon={<CheckCircle size={20}/>}
            ></HighlightMessagesComponent>
          </div>}
          {requestFeatureData?.requestedFeature?.map((feature:any, index:any) => {
            return (
              <Card
                key={feature.id}
                className={`selection-card ${feature.requestedFeatureStatus === 4 ? "success" : ""} mb-xs`}
              >
                {getVerificationStep(feature.requestedFeatureType, feature)}
              </Card>
            );
          })}
          <div className='mt-m'>
            <PrimaryButtonComponent
              buttonText= {isComplete ? RequestComponentText.shareNowBtn: RequestComponentText.continueBtn} 
              isDisabled={false}
              onClick={handleBtnClick}
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyles.solid}
              isLoading={isLoading}

            />
          </div>
          <div className='mt-m danger mb-m'>
            <PrimaryButtonComponent
              buttonText={RequestComponentText.declineBtn}
              isDisabled={false}
              onClick={showModal}
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyles.grey_outline}
            />
          </div>
        </div>
        {/* <div className='mobile-logo-wrap'>
          <img src={BetterIDLogo} className='logo-mobile' alt="Better ID Logo" />
        </div> */}
      </Card>
      <ModalTemplate title="" visible={isModalVisible} onClose={handleCancel}>
        <div className='mb-m'>
          <img src={Warning} alt="Warning" />
        </div>
        <div className="verification-process">
          <h4 className='Text-Primary mb-xl'>{RequestComponentText.subTitle5}</h4>
          {/* <div className='verification-contents'>
            <h4 className='BaseRegular mb-xl'> Are you sure you want to decline this request?</h4>
          </div> */}
          <PrimaryButtonComponent
            buttonText={RequestComponentText.confirmDeclineBtn}
            isDisabled={false}
            onClick={handleDecline}
            buttonSize={ButtonSizes.large}
            buttonStyle={ButtonStyles.solid}
          />
          <div className='mt-m'>
            <PrimaryButtonComponent
              buttonText={RequestComponentText.cancelBtn}
              isDisabled={false}
              onClick={handleCancel}
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyles.grey_outline}
            />
          </div>
        </div>
      </ModalTemplate>
    </DefaultTemplateBeforeLoginComponent>
  );
};

export default RequestComponent;