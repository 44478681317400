import React, { useState } from 'react';
import BeforeLoginHeaderComponent from './BeforeLoginHeaderComponent'; 
import AfterLoginHeaderComponent from './AfterLoginHeaderComponent'; 
import RegistrationHeaderComponent from './RegistrationHeaderComponent';
import MainHeaderComponent from './MainHeaderComponent';

interface BetterIdHeaderComponentProps {
  isLoginProps:boolean;
  isRegisterProps:boolean;
  hideCaretLeft?: boolean;
  isLoggedin?: boolean;
}

const BetterIdHeaderComponent: React.FC<BetterIdHeaderComponentProps> = (props) => {
  const { isLoginProps, isRegisterProps, hideCaretLeft,isLoggedin } = props;
  const [isLogin, setIsLogin] = useState(isLoginProps);
  const [isRegister, setIsRegister] = useState(isRegisterProps);


  return (
    <div> 
      {isLogin && (
        <BeforeLoginHeaderComponent /> // better id logo and need help/hamburger
      )}
      {isRegister && (
        <RegistrationHeaderComponent hideCaretLeft={hideCaretLeft}  isLoggedin={isLoggedin} /> // only a carrot left on mobile and  better id logo and need help on desktop
      )}
      {isLogin && isRegister && (
        <AfterLoginHeaderComponent /> // All the user details and options
      )}
       {!isLogin && !isRegister && ( 
        <MainHeaderComponent isLoggedin={isLoggedin} /> //  if using flowtemplate -only a carror left on mobile and  better id logo and need help on desktop . If using default template no carrot left on mobile and desktop same
      )}
    </div>
  );
};

export default BetterIdHeaderComponent;
