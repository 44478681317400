import React, { useState,useEffect } from 'react';
import { Card } from 'antd';
import { ImageSquare, Eyeglasses, Sun, Camera,Smiley } from "@phosphor-icons/react";
import { ButtonSizes, PrimaryButtonComponent, ButtonStyles,HighlightMessagesComponent, HighlightMessagesActionWarningComponent, MessageType } from "@betterhome/components";
import CameraIcon from '../../../assets/Icons/camera.svg';
import CameraImg from '../../../assets/Icons/camera-icon.svg';
import SunImg from '../../../assets/Icons/sun.svg';
import SmileyImg from '../../../assets/Icons/smile.svg';
import ImageIcon from '../../../assets/Icons/image.svg';
import CrosshairIcon from '../../../assets/Icons/cross-hair.svg';
import glasses from '../../../assets/Icons/glasses.svg';
import BetterIDLogo from '../../../assets/Icons/betterid.svg';
import FlowTemplateComponent from '../../../Components/Templpate/FlowTemplate';
import { useNavigate } from 'react-router-dom';
import Text from "../IdentityVerification/Constants.json"

const IDVStartComponent = () => {
  const navigate = useNavigate()
  const [hasCameraPermission, setHasCameraPermission] = useState<boolean | null>(null);
  const [reloadCameraKey, setReloadCameraKey] = useState(0);
  const IDVStartText = Text.IDVStart
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  useEffect(() => {
      const hasReloadedBefore = sessionStorage.getItem('isCameraOn');
      if (hasReloadedBefore) {
          sessionStorage.removeItem('isCameraOn');
          window.location.reload();
      }
  }, [reloadCameraKey]);

  // useEffect(() => {
  //   const checkCameraPermission = async () => {
  //     navigator.permissions.query({ name: 'camera' as PermissionName }).then(permissionStatus => {
  //       if(permissionStatus.state == 'denied'){
  //         setHasCameraPermission(false);
  //       }
  //       else if(permissionStatus.state == 'granted' || permissionStatus.state == 'prompt'){
  //         setHasCameraPermission(true);
  //       }
  //     });
  //   };
  //   checkCameraPermission();
  //   return () => {
  //   };
  // }, []);



  useEffect(() => {
    const checkCameraPermission = async () => {
      // Check if the Permissions API is supported
      if ('permissions' in navigator) {
        try {
          const permissionStatus = await navigator.permissions.query({ name: 'camera' as PermissionName });
          setHasCameraPermission(permissionStatus.state === 'granted' || permissionStatus.state === 'prompt');
        } catch (e) {
          try {
            await navigator.mediaDevices.getUserMedia({ video: true });
            setHasCameraPermission(true);
          } catch (err) {
            setHasCameraPermission(false);
          }
        }
      }
    };

    checkCameraPermission();

    return () => {
      // Cleanup if necessary
    };
  }, []);

  useEffect(() => {
    // Log the updated value of hasCameraPermission whenever it changes
  }, [hasCameraPermission]);

  interface VerificationStepProps {
    icon: React.ReactNode;
    title: string;
  }

  const handleBtnClick = () => {
    navigate("/image-capture")
}

  const VerificationStep: React.FC<VerificationStepProps> = ({ icon, title }) => {
    return (
      <div className="verification-step mt-5 mb-5">
        <div className="step-icon">{icon}</div>
        <div className="BaseRegular">{title}</div>
      </div>
    );
  };


  return (
    <FlowTemplateComponent isLoggedin={isAuthenticated}>
      <Card className="page-card centered-content">
        <div className='mb-m'>
          <img src={CameraIcon} alt="Camera Icon" />
        </div>
        <div className="verification-process">

          <h4 className='Text-Primary'>{IDVStartText.selfieTitle}</h4>
          <div className='verification-contents  mb-m mt-m'>
            <h4 className='BaseRegular'> {IDVStartText.selfieSubtitle}</h4>
          </div>
         
         {!hasCameraPermission && <div className='mb-m'> <HighlightMessagesComponent
          Title=""
          Textmsg={IDVStartText.textmessage}
          messageType={MessageType.warning}
          closeIcon={null}
        ></HighlightMessagesComponent>
         </div>}
         
         <div className='Selfie-List'>
          <VerificationStep 
            icon={<img src={CameraImg} className='Text-Subtitle' alt="Camera Icon" />}
            title={IDVStartText.subtitleInstruction1}
          />
         
          <VerificationStep
            icon={<img src={SunImg} className='Text-Subtitle' alt="Sun" />}
            title={IDVStartText.subtitleInstruction2}
          />
         
          <VerificationStep
            icon={<img src={ImageIcon} className='Text-Subtitle' alt="ImageSquare" />}
            title={IDVStartText.subtitleInstruction3}
          />

            <VerificationStep
            icon={<img src={glasses} className='Text-Subtitle' alt='glasses'/>}
            title={IDVStartText.subtitleInstruction4}
          />
            <VerificationStep
            icon={<img src={CrosshairIcon} className='Text-Subtitle' alt="Crosshair Icon" />}
            title={IDVStartText.subtitleInstruction5}
          />

            <VerificationStep
            icon={<img src={SmileyImg} className='Text-Subtitle' alt="Smiley" />}
            title={IDVStartText.subtitleInstruction6}
          />
          </div>
         
          <div className='mt-m'>
            <PrimaryButtonComponent
              buttonText={IDVStartText.btnTextContinue}
              isDisabled={false}
              onClick={handleBtnClick}
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyles.solid}
            />
          </div>
        </div>
      </Card>
    </FlowTemplateComponent>

  );
};

export default IDVStartComponent;