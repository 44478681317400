import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { ButtonSizes, PrimaryButtonComponent, ButtonStyles, TextInputComponent, FormSelectComponent } from "@betterhome/components";
import BetterIDLogo from '../../assets/Icons/betterid.svg';
import Location from '../../assets/Icons/location-map.svg';
import FlowTemplateComponent from '../../Components/Templpate/FlowTemplate';
import SlideoverComponent from '../../Components/Templpate/SlideoverTemplateComponent';
import { useNavigate } from 'react-router-dom';
import Text from "./Constants.json";
import { MapPin, X } from "@phosphor-icons/react";
import {useLoading} from "../../services/Utils/LoadingContext";
import { updateCustomerRequestFeatureStatusAPI, verifyAddress } from '../../services/apiService/api';
import ModalTemplate from '../../Components/Templpate/ModalTemplateComponent';
import Warning from '../../assets/Icons/warning.svg';

interface POAManualProps {}

const POAManualComponent: React.FC<POAManualProps> = () => {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  const [addressLine1, setAddressLine1] = useState<string>('');
  const [addressLine2, setAddressLine2] = useState<string>('');
  const [suburb, setSuburb] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [isReadOnly, setIsReadOnly] = useState(false);
  const userAddress = sessionStorage.getItem("userAddress");
  const POAManualComponentText = Text.POAManualComponent;
  const [isSlideoverVisible, setIsSlideoverVisible] = useState(true);
  const [verifyAddressReqObj, setVerifyAddressReqObj] = useState({});
  const storedProfile = sessionStorage.getItem("userDataSession");
  const { isLoading, setLoading } = useLoading();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reqName, setReqName] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const requestIdData = sessionStorage.getItem("requestIdData")
  const handleCloseSlideover = () => {
     setIsSlideoverVisible(false);
   };
  useEffect(() => {
    if (userAddress) {
      const parsedAddress = JSON.parse(userAddress);
      setAddressLine1(parsedAddress.addressLine1 || '');
      setAddressLine2(parsedAddress.addressLine2 || '');
      setSuburb(parsedAddress.suburb || '');
      setPostalCode(parsedAddress.postalCode || '');
      setIsReadOnly(true)
      if (storedProfile) {
        const userDataObj = JSON.parse(storedProfile);
        let modifiedPhoneNumber = userDataObj.customerMobile;
        if (modifiedPhoneNumber.startsWith("27")) {
            modifiedPhoneNumber = "0" + modifiedPhoneNumber.slice(2);
        }
        const verifyAddress = {
        "idNumber": userDataObj.SaId,
        "firstName": userDataObj.givenName,
        "surname": userDataObj.familyName,
        "phoneNumber": modifiedPhoneNumber,
        "email": userDataObj.customerEmail,
        "address": `${parsedAddress.addressLine1} ${parsedAddress.addressLine2}, ${parsedAddress.suburb}, ${parsedAddress.postalCode}`
        }
        setVerifyAddressReqObj(verifyAddress)
      };
    }
    if (requestIdData){
      const requestDataObj = JSON.parse(requestIdData);
      setReqName(requestDataObj.requestorInfo.firstName + " "+requestDataObj.requestorInfo.lastName)
      setCompanyName(requestDataObj.requestorInfo.company.name)
      //console.log(requestDataObj.requestorInfo.firstName + " "+ requestDataObj.requestorInfo.firstName)
    }
  }, []);

  useEffect(() => {
   
}, []);

const postAddress = async () => {
  try {
    //const res = await verifyAddress(verifyAddressData);
    if(verifyAddressReqObj && Object.keys(verifyAddressReqObj).length > 0){
      const res = await verifyAddress(verifyAddressReqObj);
      setLoading(false)
      return res.resdata.matchPercentage 
      // Check if response is successful and contains data
    }
  } catch (error) {}
};

  const updateAddress1 = (value: string) => {
    setAddressLine1(value);
  };
  const updateAddress2 = (value: string) => {
    setAddressLine2(value);
  };
  const updateSuburb = (value: string) => {
    setSuburb(value);
  };
  const updatePostalCode = (value: string) => {
    setPostalCode(value);
  };

  const btnContinueClicked = async () => {  
    setLoading(true)
    if (isValidDetails()) {
          const matched = await postAddress()
          if(!matched)
            navigate("/poa-success"); 
          else{
            setIsModalVisible(true)
          }
      }
  };

  const isValidDetails = () => {
    return (addressLine1.length > 0 && addressLine2.length > 0 && suburb.length > 0 && postalCode.length > 0) || isReadOnly;
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedOptionValue, setSelectedOptionValue] = useState<string>('');

  const handleSelectChange = (value: string) => {
    setSelectedOptionValue(value);
  };

  const handleDisabledOptionClick = () => {
    setDropdownVisible(false);
  };

  const options = [
    { value: '', label: 'Select Province', disabled: true, className: 'provtext mobileshow', icon: <X weight="regular" size={24}/>,  onClick: handleDisabledOptionClick },
    { value: 'EC', label: 'Eastern Cape', icon: <MapPin weight="regular" size={24}/> },
    { value: 'FS', label: 'Free State', icon: <MapPin weight="regular" size={24}/> },
    { value: 'GP', label: 'Gauteng' , icon: <MapPin weight="regular" size={24}/>},
    { value: 'KZN', label: 'Kwazulu Natal' , icon: <MapPin weight="regular" size={24}/>},
    { value: 'L', label: 'Limpopo' , icon: <MapPin weight="regular" size={24}/>},
    { value: 'MP', label: 'Mpumalunga' , icon: <MapPin weight="regular" size={24}/>},
    { value: 'NC', label: 'Northern Cape', icon: <MapPin weight="regular" size={24}/> },
    { value: 'NW', label: 'North-West Province' , icon: <MapPin weight="regular" size={24}/>},
    { value: 'WC', label: 'Western Cape', icon: <MapPin weight="regular" size={24}/> },
  ];

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleContinue = async () => {
    // setIsSlideoverVisible(false);
    //const statusUpdate = await updateCustomerRequestFeatureStatusAPI('4',requestFeatureId)
    //navigate("/request",{state:{from:"poa-manual"}});
    navigate("/dashboard");
  };

  return (
    <FlowTemplateComponent isLoggedin={isAuthenticated}>
      <Card className="page-card centered-content">
        <div className='mb-m'>
          <img src={Location} alt="Location" />
        </div>
        <div className="verification-process">
          <div className='verification-contents'> 
            <h4 className='Text-Primary mb-xl'>{POAManualComponentText.title}</h4>
          </div>
          <TextInputComponent
            label={POAManualComponentText.subTitle1}
            name={POAManualComponentText.subTitle1}
            value={addressLine1}
            onChange={updateAddress1}
            validateEmpty
           readOnly={isReadOnly}
          ></TextInputComponent>
          <TextInputComponent
            label={POAManualComponentText.subTitle2}
            name={POAManualComponentText.subTitle2}
            value={addressLine2}
            onChange={updateAddress2}
            validateEmpty
            readOnly={isReadOnly}
          ></TextInputComponent>
          <TextInputComponent
            label={POAManualComponentText.subTitle3}
            name={POAManualComponentText.subTitle3}
            value={suburb}
            onChange={updateSuburb}
            validateEmpty
            readOnly={isReadOnly}
          ></TextInputComponent>
          <TextInputComponent
            label={POAManualComponentText.subTitle4}
            name={POAManualComponentText.subTitle4}
            value={postalCode}
            onChange={updatePostalCode}
            validateEmpty
            readOnly={isReadOnly}
          ></TextInputComponent>
          <FormSelectComponent
            label={POAManualComponentText.subTitle5}
            options={options} 
            onChange={handleSelectChange}
            dropdownVisible={dropdownVisible}
            setDropdownVisible={setDropdownVisible}
          ></FormSelectComponent>
          <div className='mt-xs'>
            <PrimaryButtonComponent
              buttonText={POAManualComponentText.btnContinueText}
              isDisabled={!isValidDetails()}
              onClick={btnContinueClicked}
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyles.solid}
              isLoading={isLoading}
            />
          </div>
        </div>
      </Card>
      <ModalTemplate title="" visible={isModalVisible} onClose={handleCancel}>
        <div className='mb-m'>
          <img src={Warning} alt="Warning" />
        </div>
        <div className="verification-process">
          <h4 className='Text-Primary mb-xl'>Address verification unsuccessful.</h4>
          <div className='verification-contents'>
            <h4 className='BaseRegular mb-xl'>We are currently improving our address verification service. You may be required to manually verify your address with {reqName} from {companyName}.</h4>
          </div>
          {/* <PrimaryButtonComponent
            buttonText="Yes, Decline"
            isDisabled={false}
            onClick={handleDecline}
            buttonSize={ButtonSizes.large}
            buttonStyle={ButtonStyles.solid}
          /> */}
          <div className='mt-m'>
            <PrimaryButtonComponent
              buttonText="Continue"
              isDisabled={false}
              onClick={handleContinue}
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyles.solid}
            />
          </div>
        </div>
      </ModalTemplate>
    </FlowTemplateComponent>
  );
};

export default POAManualComponent;
