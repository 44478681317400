import React, { useEffect, useState, useContext } from "react";
import { Card, Button, Spin } from "antd"; // Import Spin component for loading indicator
import Text from "../Constants.json";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultTemplateBeforeLoginComponent from "../../../Components/Templpate/DefaultTemplate";
import { CustomerRequests } from "../../../services/apiService/api";
import { DataContext } from "../../../services/Utils/DataContext";
import NewRequestCardComponent from "./NewRequestCardComponent";
import PreviousRequestCardComponent from "./PreviousRequestCardComponent";
import ModalTemplate from "../../../Components/Templpate/ModalTemplateComponent";
import Warning from "../../../assets/Icons/warning.svg";
import { msalInstance } from '../../../index';

const DashboardComponent = () => {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true); // Initialize loading state
  const DashboardComponentText = Text.DashboardComponent;
  const location = useLocation();
  // const { userProfile, setUserProfile } = useContext(DataContext);
  const { userData, setUserData } = useContext(DataContext);
  const [customerName,setCustomerName] = useState("")
  const storedProfile = sessionStorage.getItem("userDataSession");
  const [error, setError] = useState<string | null>(null);
  let navigate = useNavigate();

  interface Status {
    id: number;
    name: string;
  }
  function toSentenceCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const statusesStr = sessionStorage.getItem("customerRequestStatus");
  let statuses: Status[] = [];
  if (statusesStr) {
    statuses = JSON.parse(statusesStr);
  }

  const onErrorClose = () => {
    setError(null);
    setLoading(true)
    msalInstance.clearCache()
    sessionStorage.clear();
    localStorage.clear();
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: "/" // The URL to redirect after logout
    });
  };

  function getStatusNameById(id: number): any {
    const status = statuses.find(status => status.id === id);
    return status ? toSentenceCase(status.name) : "";
  }

  useEffect(() => {
    const fetchData = async () => {
      const mobileNumber = userData ? userData.customerMobile : JSON.parse(sessionStorage.getItem("userDataSession") || "{}").phoneNumber || JSON.parse(sessionStorage.getItem("userDataSession") || "{}").customerMobile || "";
      //const mobileNumber = ""
      try {
        const res = await CustomerRequests(mobileNumber);
        if ('data' in res && res.data && res.data.length > 0 ) {
          setData(res.data);
          setLoading(false)
          setError(null);
          sessionStorage.setItem("userRequestData",JSON.stringify(res.data));
          if (storedProfile) {
              const userDataObj = JSON.parse(storedProfile);
              setCustomerName(userDataObj.givenName)
          }
          else {
            const filteredData = res.data.filter((customer: { customerFirstName: any; customerLastName: any; }) => (customer.customerFirstName && customer.customerFirstName.length > 0) || (customer.customerLastName && customer.customerLastName.length > 0));
            if (filteredData.length > 0){
              const name  = filteredData[0].customerFirstName ? filteredData[0].customerFirstName : filteredData[0].customerLastName
              setCustomerName(name)
            }
            else{
              setCustomerName('')
            }
          }
        } 
        else{
          if(res.status == "error"){
            if (storedProfile) {
                const userDataObj = JSON.parse(storedProfile);
                setCustomerName(userDataObj.givenName)
            }
            const error = DashboardComponentText.sessionExpiredError;
            setError(error);
            setLoading(false)
            return false;
          }
        }
      } catch (error) {
        // Handle error
      }
      // } finally {
      //   if (storedProfile) {
      //       const userDataObj = JSON.parse(storedProfile);
      //       setCustomerName(userDataObj.givenName)
      //   }
      //   setLoading(false); // Set loading to false when request is completed
      //   console.log("Finally*******************888")
      // }
    };
    fetchData();
  }, [userData,userData?.customerMobile,userData?.phoneNumber]);

  const statusOneCount = data ? data.filter((item: any) => item.status === 1).length : 0;
  
  return (
    <DefaultTemplateBeforeLoginComponent
      isLoginProps={isAuthenticated}
      isRegisterProps={isAuthenticated}
    >
    <div className="page-card-none">
      <Card className="page-card centered-content">
        <div className="verification-process">
          {loading ? ( // Display loading indicator while loading
            <Spin size="small" />
          ) : (
            <>
              {data && data.length > 0 ? (
                <>
                  <h3 className="Text-Primary">
                    {DashboardComponentText.title} {customerName}
                  </h3>
                  <h5 className="SmallRegular mb-xl">
                  {DashboardComponentText.subTitle1 + statusOneCount + (statusOneCount === 1 ? DashboardComponentText.subTitle4 : DashboardComponentText.subTitle5)}
                  </h5>
                </>
              ) : (
                <>
                  <h3 className="Text-Primary">
                    {DashboardComponentText.title} {customerName}
                  </h3>
                  <h5 className="SmallRegular mb-xl">
                    {DashboardComponentText.subTitle3}
                  </h5>
                </>
              )}

              {data && data.length > 0 ? (
                <>
                  { data.map((req: any, index: any) => (
                      req.status === 1 && (
                        <NewRequestCardComponent
                          key={index}
                          title={req.requestorInfo?.jobTitle ?? ""}
                          name={req.requestorInfo?.firstName ?? ""}
                          content={req.requestorInfo?.company.name ?? ""} //company name from api
                          buttonText={DashboardComponentText.reqButton}
                          showIdv={req.requestedFeature.kyc}
                          showPoa={req.requestedFeature.addressVerify}
                          image={req.requestorInfo?.profileImage ?? ""}
                          requestId={req.id?? ""}
                          requestIdData={req}
                          companyLogo={req.requestorInfo?.company.logo ?? ""}
                        />
                      )
                      ))
                  }
                </>
              ) : (
                false
              )}
              {/* {data && data.length > 0 && <h6 className="Text-Primary mb-m mt-m">
                {DashboardComponentText.subTitle2}
              </h6>} */}
              {data && data.length > 0 && (
                <>
                  {data.some((req: any) => req.status !== 1) && (
                    <h6 className="Text-Primary mb-m mt-m">
                      {DashboardComponentText.subTitle2}
                    </h6>
                  )}
                  {data.map((req: any, index: any) => (
                    req.status !== 1 && (
                      <PreviousRequestCardComponent
                        image={req.requestorInfo?.profileImage ?? ""}
                        companyLogo={req.requestorInfo?.company.logo ?? ""}
                        key={index}
                        name={req.requestorInfo?.name ?? ""}
                        content={req.requestorInfo?.company.name ?? ""} //company name from api
                        BadgeText={getStatusNameById(req.status)}
                        BadgeStatus={getStatusNameById(req.status)}
                      />
                    )
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </Card>
      <ModalTemplate
        title=""
        visible={error ? true : false}
        onClose={onErrorClose}
      >
        <div className="mb-m">
          <img src={Warning} alt="Warning" />
        </div>
        <div className="verification-process">
          <h4 className="Text-Primary mb-m">Error</h4>
          <h4 className="BaseRegular mb-xl">{error}</h4>

          {/* <PrimaryButtonComponent
                    buttonText="Back to Dashboard"
                    isDisabled={false}
                    onClick={handleBackDashboard}
                    buttonSize={ButtonSizes.large}
                    buttonStyle={ButtonStyles.solid}
                    /> */}
        </div>
      </ModalTemplate>
    </div>
    </DefaultTemplateBeforeLoginComponent>
  );
};

export default DashboardComponent;
