import React, { useState } from 'react';
import { Card } from 'antd';
import { PrimaryButtonComponent, ButtonSizes, ButtonStyles } from "@betterhome/components";
import { useNavigate } from 'react-router-dom';

interface NewRequestCardProps {
  name: string;
  title: string;
  content: string;
  buttonText: string;
  showIdv: boolean;
  showPoa:boolean;
  image:string;
  requestId:string;
  companyLogo:string;
  requestIdData:{};
}

const NewRequestCardComponent: React.FC<NewRequestCardProps> = ({ content,name, title, buttonText,showIdv,showPoa,image,requestId,companyLogo,requestIdData}) => {
  const [isValidData, setIsValidData] = useState(true);

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/request", { "state": { "isValidData": { isValidData },"showIdv":showIdv, "showPoa":showPoa, "requestId":requestId} });
    sessionStorage.setItem('showIdv', `${showIdv}`);
    sessionStorage.setItem('showPoa', `${showPoa}`);
    sessionStorage.setItem('requestId', requestId);
    sessionStorage.setItem('requestIdData', JSON.stringify(requestIdData));
  };

  return (
    <Card className="mobile-card centered-content mb-s">
      <div className='profile-container mb-l'>
        {image && <img src={image} className='agent-profile-image' alt="Agent photo" />}
        {companyLogo && <img src={companyLogo} className={image? 'agent-agency-logo' :'agent-agency-logo-single'} alt="Company Logo" />}

      </div>
      <div className="verification-process">
        <h5 className='Text-Primary mb-xxs'>{name}</h5>
        <span className='TinyRegular Text-Subtitle mb-xs'>{title}</span>
        <h5 className='SmallRegular'>{content}</h5>

        <div className='mt-l'>
          <PrimaryButtonComponent
            buttonText={buttonText}
            isDisabled={false}
            onClick={handleButtonClick}
            buttonSize={ButtonSizes.medium}
            buttonStyle={ButtonStyles.solid}
          />
        </div>
      </div>
    </Card>
  );
};

export default NewRequestCardComponent;
