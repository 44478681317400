import React from 'react';
import { Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './SlideoverTemplateComponent.scss'; 

interface SlideoverComponentProps {
  title?: string;
  children?: React.ReactNode;
  visible: boolean; 
  onClose: () => void; 
}

const SlideoverComponent: React.FC<SlideoverComponentProps> = ({ title, children, visible, onClose}) => {

  const placement = window.innerWidth <= 768 ? 'bottom' : 'right';

  return (
    <Drawer
      placement={placement}
      closable={false}
      onClose={onClose}
      visible={visible}
      width={450} 
      className="custom-drawer" 
      title={
        <div className='headeralign'>
          <CloseOutlined className="close-icon" onClick={onClose} style={{ cursor: 'pointer' }} />
          <div>{title}</div>
        </div>
      }
    >
      {children}
    </Drawer>
  );
};

export default SlideoverComponent;
