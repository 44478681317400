import React from 'react';
import { Layout } from 'antd';
import BetterIdHeaderComponent from '../Headers/BetterIdHeaderComponent';

interface CentredTemplateProps {
  children?: React.ReactNode;
  isLoggedin?: boolean;
}

const { Content } = Layout;

const CentredTemplateComponent: React.FC<CentredTemplateProps> = ({ children, isLoggedin}) => {


  return (
    <Layout>
      <BetterIdHeaderComponent isLoginProps={false} isRegisterProps={false} isLoggedin={isLoggedin}/>
      <Content className="page-wrapper-centered">
        {children}
      </Content>
    </Layout>
  );
};

export default CentredTemplateComponent;
