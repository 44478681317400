import React, { useState, useEffect } from "react";
import { Layout, Button, Badge, Menu, Dropdown, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import {
  List,
  X,
  EnvelopeSimple,
  Phone,
  DeviceMobileCamera,
} from "@phosphor-icons/react";
import {
  ButtonSizes,
  ButtonStyles,
  ButtonIcons,
  DefaultButtonComponent,
  StackedButtonComponent,
  ButtonClick,
} from "@betterhome/components";
import BetterIDLogo from "../../assets/Icons/betterid.svg";
import Terms from "../../assets/Icons/terms.svg";
import Privacy from "../../assets/Icons/privacy.svg";
import UserImg from "../../assets/Icons/user-circle.svg";
import DashboardImg from "../../assets/Icons/dashboard.svg";
import Help from "../../assets/Icons/help.svg";
import Logout from "../../assets/Icons/logout.svg";
import { msalInstance } from "../..";
import SlideoverComponent from "../Templpate/SlideoverTemplateComponent";

interface AfterLoginHeaderProps {}

const { Header } = Layout;

const AfterLoginHeaderComponent: React.FC<AfterLoginHeaderProps> = () => {
  interface VerificationStepProps {
    icon: React.ReactNode;
    title: string;
    suffixicon?: React.ReactNode;
  }

  const VerificationStep: React.FC<VerificationStepProps> = ({
    icon,
    title,
    suffixicon,
  }) => {
    return (
      <div
        className="verification-step"
        style={{ padding: "0px 0px", justifyContent: "space-between" }}
      >
        <div className="step-icon">{icon}</div>
        <div className="BaseRegular">{title}</div>
        <div className="suffix-icon">{suffixicon}</div>
      </div>
    );
  };
  let navigate = useNavigate();
  const [isSlideoverVisible, setIsSlideoverVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userName, setUserName] = useState<string>(""); // Initialize userName state
  const [userEmail, setUserEmail] = useState<string>(""); // Initialize userName state
  const [userMobile, setUserMobile] = useState<string>(""); // Initialize userName state

  const handleDropdownVisibleChange = (visible: boolean) => {
    setIsMenuOpen(visible);
  };

  const handleLogout = () => {
    let account = msalInstance.getActiveAccount();
    msalInstance.clearCache();
    sessionStorage.clear();
    localStorage.clear();
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: "/", // The URL to redirect after logout
    });
    //localStorage.setItem("isAuthenticated", "false");
  };

  const handleCloseSlideover = () => {
    setIsSlideoverVisible(false);
  };

  const storedProfile = sessionStorage.getItem("userDataSession");

  useEffect(() => {
    if (storedProfile) {
      const userDataObj = JSON.parse(storedProfile);
      setUserName(userDataObj.givenName || userDataObj.customerName || ""); // Set userName if it exists in the stored profile
      setUserEmail(userDataObj.customerEmail);
      setUserMobile(userDataObj.customerMobile);
    }
  }, [storedProfile]); // Listen for changes to userDataSession

  const handleMenuClick = (event: any) => {
    switch (event.key) {
      case "1":
        navigate("/dashboard");
        break;
      case "2":
        navigate("/help");
        break;
      case "3":
        navigate("/terms-of-use");
        break;
      case "4":
        navigate("/privacy-policy");
        break;
      case "5":
        //navigate('/profile');
        setIsSlideoverVisible(true);
        break;
      case "6":
        handleLogout();
        break;
      default:
        break;
    }
  };

  const menu = (
    <div className="menudropdownds">
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="0" className="mobileshow">
          <div className="alignmenuitem">
            <img src={BetterIDLogo} alt="Better ID Logo" />{" "}
            <span className="closebg">
              <X color="var(--text-color-dynamic)" weight="regular" size={24} />
            </span>
          </div>
        </Menu.Item>
        <Menu.Item key="1">
          <span className="menuitem">
            <img src={DashboardImg} alt="Dashboard" />
            <span className="menulabel">Dashboard</span>
          </span>
        </Menu.Item>
        <Menu.Item key="2">
          <span className="menuitem">
            <img src={Help} alt="Help" />
            <span className="menulabel">Help</span>
          </span>
        </Menu.Item>
        <Menu.Item key="3">
          <span className="menuitem">
            <img src={Privacy} alt="Privacy" />
            <span className="menulabel">Terms of Use</span>
          </span>
        </Menu.Item>
        <Menu.Item key="4">
          <span className="menuitem">
            <img src={Terms} alt="Terms" />
            <span className="menulabel">Privacy Policy</span>
          </span>
        </Menu.Item>
        <Menu.Item key="5">
          <span className="menuitem">
            <img src={UserImg} alt="User" />
            <span className="menulabel">Profile</span>
          </span>
        </Menu.Item>
        <Menu.Item key="6">
          <span className="menuitem">
            <img src={Logout} alt="Logout" />
            <span className="menulabel">Logout</span>
          </span>
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <Layout>
      <Header className="mobile-header">
        <div className="default-header">
          <div className="">
            <img src={BetterIDLogo} alt="Better ID Logo" />
          </div>
          <div className="menudropdown">
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              placement="bottomRight"
              open={isMenuOpen}
              onOpenChange={handleDropdownVisibleChange}
              overlayClassName="customdropdown"
            >
              <Button
                type="link"
                className="hamburger"
                style={{ padding: "0px", margin: "0px" }}
              >
                <List
                  color="var(--text-color-dynamic)"
                  weight="regular"
                  size={24}
                />
              </Button>
            </Dropdown>
          </div>
        </div>
      </Header>

      <Header className="desktop-header">
        <div className="logo">
          <img src={BetterIDLogo} alt="Better ID Logo" />
        </div>
        <div className="header-icons">
          <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
            <DefaultButtonComponent
              buttonText={userName}
              isDisabled={false}
              onClick={(event) => event.preventDefault()}
              buttonSize={ButtonSizes.medium}
              buttonStyle={ButtonStyles.grey_outline}
              buttonIcon={ButtonIcons.UserCircle}
              suffixIcon={ButtonIcons.CaretDown}
            />
          </Dropdown>
        </div>
      </Header>
      <SlideoverComponent
        title=""
        visible={isSlideoverVisible}
        onClose={handleCloseSlideover}
      >
        <div className="verification-process">
          <h4 className="Text-Primary mb-m">{userName}</h4>
          <div className="mb-xl align-stackbtn">
            <StackedButtonComponent
              buttonText="Email"
              isDisabled={false}
              onClick={(event) => ButtonClick("TransparentLarge", event)}
              buttonStyle={ButtonStyles.grey_outline}
              buttonIcon={ButtonIcons.Mail}
            />
            <StackedButtonComponent
              buttonText="WhatsApp"
              isDisabled={false}
              onClick={(event) => ButtonClick("TransparentLarge", event)}
              buttonStyle={ButtonStyles.grey_outline}
              buttonIcon={ButtonIcons.PlusCircle}
            />
            <StackedButtonComponent
              buttonText="Call"
              isDisabled={false}
              onClick={(event) => ButtonClick("TransparentLarge", event)}
              buttonStyle={ButtonStyles.grey_outline}
              buttonIcon={ButtonIcons.PlusCircle}
            />
          </div>
          <VerificationStep
            icon={
              <EnvelopeSimple
                className="Text-Subtitle"
                weight="regular"
                size={24}
              />
            }
            title={userEmail}
          />
          <Divider className="verification-divider" />
          <VerificationStep
            icon={
              <DeviceMobileCamera
                className="Text-Subtitle"
                weight="regular"
                size={24}
              />
            }
            title={userMobile}
          />
        </div>
      </SlideoverComponent>
    </Layout>
  );
};

export default AfterLoginHeaderComponent;
