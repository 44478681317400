import { Navigate } from 'react-router-dom';

const AuthGuard = ({ children }) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  //const isAuthenticated = true;

  return isAuthenticated ? children : <Navigate to="/" />;
};

export default AuthGuard;
