import React from 'react';
import { Layout, Card } from 'antd';
import BetterIdHeaderComponent from '../Headers/BetterIdHeaderComponent';

interface DefaultTemplate {
    children?: React.ReactNode;
    isLoginProps?: boolean;
    isRegisterProps?: boolean;
}

const { Content } = Layout;

const DefaultTemplate: React.FC<DefaultTemplate> = ({ children, isLoginProps = true , isRegisterProps = false}) => {

    return (
        <div className='default-header-mobile'>
            <Layout>
                <BetterIdHeaderComponent isLoginProps={isLoginProps} isRegisterProps={isRegisterProps} />
                <Content className="page-wrapper">
                    <div className='w100'>
                        <div className='placeholder-section'>
                            {children}
                        </div>
                    </div>
                </Content>
            </Layout>
        </div>
    );
};

export default DefaultTemplate;