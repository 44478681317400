  // @ts-nocheck
  import React, { useState, useMemo, useEffect } from "react";
  import { getCustomerRequestStatusAPI,CustomerRequests } from "../apiService/api";

  interface Status {
    id: number;
    name: string;
  }
  interface userData{
    customerMobile: string;
    customerEmail: string;
    customerName: string;
    SaId:string;
    phoneNumber:string;
  }

  interface UserProfile {
    id: string;
    customerMobile: string;
    customerEmail: string;
    customerName: string;
    requestorId: string;
    companyId: string;
    SaId:string;
    requestedFeature: {
      id: string;
      customerRequestsId: string;
      kyc: boolean;
      kycId: string | null;
      addressVerify: string;
    };
    status: number;
    personId: string | null;
  }
  

  interface DataContextValue {
    customerRequestStatus: Status[] | null;
    setCustomerRequestStatus: (status: Status[]) => void;
    userProfile: UserProfile | null; // New state
    setUserProfile: (profile: UserProfile) => void; // New state setter
    userData: userData | null; // New state
    setUserData: (userData) => void; // New state setter
  }

  const initialContextValue: DataContextValue = {
    customerRequestStatus: null,
    setCustomerRequestStatus: () => {},
    userProfile: {
      id: "",
      customerMobile: "",
      customerEmail: "",
      customerName: "",
      requestorId: "",
      companyId: "",
      SaId: null,
      requestedFeature: {
        id: "",
        customerRequestsId: "",
        kyc: false,
        kycId: null,
        addressVerify: "",
      },
      status: 0,
      personId: null,
    },
    setUserProfile: () => {}, // Setter function for userProfile
    userData:{
      customerMobile: "",
      customerEmail: "",
      customerName: "",
      SaId:"",
      phoneNumber:""
    },
    setUserData: () => {}
  };

  const DataContext = React.createContext<DataContextValue>(initialContextValue);

  const DataContextProvider = (props: any) => {
    const [customerRequestStatus, setCustomerRequestStatus] = useState<
      string[] | null
    >(null);
    const [userProfile, setUserProfile] = useState<UserProfile | null>(null); // New state
    const [userData, setUserData] = useState<UserData | null>(null); // Define userData state and setter

    // useEffect(() => {
    //   async function fetchData() {
    //     try {
    //       const status = await getCustomerRequestStatusAPI();
    //       setCustomerRequestStatus(status);
    //     } catch (error) {
    //       // Handle error
    //       console.error("Error fetching customer request status:", error);
    //     }
    //   }

    //   if (!customerRequestStatus) {
    //     fetchData();
    //   }
    // }, []); // Empty dependency array to ensure the effect runs only once


    // useEffect(() => {
    //   async function fetchData() {
    //     try {
    //       const profile = await CustomerRequests();
    //       setUserProfile(profile);
    //     } catch (error) {
    //       console.error("Error fetching user profile:", error);
    //     }
    //   }

    //   if (!userProfile) {
    //     fetchData();
    //   }
    // }, [userProfile]); // Fetch only when userProfile is null

    const contextValue = useMemo(
      () => ({
        customerRequestStatus: customerRequestStatus,
        setCustomerRequestStatus: setCustomerRequestStatus,
        userProfile, // Include userProfile in context value
        setUserProfile, // Include setUserProfile in context value
        userData,
        setUserData
      }),
      [customerRequestStatus, setCustomerRequestStatus, userProfile, setUserProfile, userData, setUserData]
    );

    return (
      <DataContext.Provider value={contextValue}>
        {props.children}
      </DataContext.Provider>
    );
  };

  export { DataContextProvider, DataContext };
