import React, { useEffect, useState } from 'react';



import { FaceLivenessDetailType, FaceLivenessWebComponent} from '@regulaforensics/vp-frontend-face-components';
import {useNavigate} from "react-router-dom";

const containerStyle = {
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
} as React.CSSProperties;


const FaceLivenessComponent = () => {




    const navigate = useNavigate()
    const [isOpen, setIsOpen] = React.useState(false);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const componentRef = React.useRef<FaceLivenessWebComponent>(null);
    const listener = (data: CustomEvent<FaceLivenessDetailType>) => {
        if (data.detail.action === 'PROCESS_FINISHED') {
            if (data.detail.data?.status === 1 && data.detail.data.response) {
                sessionStorage.setItem("livenessResponse",JSON.stringify(data));
                sessionStorage.setItem("idvImage","data:image/jpeg;base64, "+data.detail.data.response.images[0]);
                navigate("/idv-status");

            }
        }

        if (data.detail?.action === 'CLOSE' || data.detail?.action === 'RETRY_COUNTER_EXCEEDED') {
            setIsOpen(false);
        }



    };


    React.useEffect(() => {
        const containerCurrent = containerRef.current;

        if (!containerCurrent) return;

            containerCurrent.addEventListener('face-liveness', listener);

        const component = document.getElementsByTagName('face-liveness')[0];
        // @ts-ignore
        component.settings = {
            startScreen:false,
            changeCamera: true,
            closeDisabled: true,
                customization: {
                    onboardingScreenStartButtonBackground:"var(--secondary)",
                    onboardingScreenStartButtonBackgroundHover: 'var(--secondary)',

                    fontFamily: 'mont-light',



                    cameraScreenSectorActive:  "var(--secondary)",
                    cameraScreenSectorTarget:  "var(--secondary)",
                    cameraScreenStrokeNormal: "var(--secondary)",
                    processingScreenProgress: "var(secondary)",
                    retryScreenRetryButtonBackground: "var(--primary)",
                    retryScreenRetryButtonBackgroundHover: "var(--primary)",

                    // /onboardingScreenIllumination: 'https://path-to-image.com',
                    //onboardingScreenAccessories: 'data:image/svg+xml;base64,PHN2...',
                   // onboardingScreenCameraLevel: importedImage,

                }
            }


            // @ts-ignore
            //component.settings = yourSettings;



        return () => {
                containerCurrent.removeEventListener('face-liveness', listener);

        }
    }, []);

    return (
        <div style={containerStyle} ref={containerRef}>

                <face-liveness  ></face-liveness>

        </div>
    );
}

export default FaceLivenessComponent;