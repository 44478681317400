import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AgentRequest from '../pages/Onboarding/AgentRequest/AgentRequest';
import RegisterSuccessComponent from '../pages/Onboarding/Registration/RegistrationSuccess/RegisterSuccessComponent';
import RegisterConsentComponent from '../pages/Onboarding/Registration/RegistrationConsent/RegisterConsentComponent';
import IdentityVerificationComponent from '../pages/KYC/IdentityVerification/IdentityVerification';
import QBAQuestionComponent from '../pages/KYC/IdentityVerification/QuestionVerification';
import SecurityQuestionComponent from '../pages/KYC/IdentityVerification/SecurityQuestionComponent';
import WrongAnswerComponent from '../pages/KYC/IdentityVerification/WrongAnswer';
import IDVStatusComponent from '../pages/KYC/IdentityVerification/IDVStatus';
import IDVSuccessComponent from '../pages/KYC/IdentityVerification/IDVSuccess';
import IDVStartComponent from '../pages/KYC/IdentityVerification/IDVStart';
import DashboardComponent from '../pages/Dashboard/DashboardComponent/DashboardComponent';
import POAManualComponent from '../pages/POA/POAManualComponent';
import POAStartComponent from '../pages/POA/POAStartComponent';
import POASuccessComponent from '../pages/POA/POASuccessComponent';
import POAUploadComponent from '../pages/POA/POAUploadComponent';
import POAUpdateComponent from '../pages/POA/POAUpdateComponent';
import POASearchAddressComponent from '../pages/POA/POASearchAddressComponent'
import RequestComponent from '../pages/Dashboard/RequestComponent/RequestComponent';
import {useState } from 'react';
import {DefaultTemplateComponent, FlowTemplateComponent, ModalTemplate} from "@betterhome/components";
import {SlideoverComponent}  from "@betterhome/components";
import {TwoColumnsTemplateComponent} from "@betterhome/components";
import { CentredTemplateComponent } from "@betterhome/components";
import FaceLivenessComponent from "../pages/KYC/IdentityVerification/FaceLiveness";
import LoadingComponent from '../services/Utils/Loading';
import LoadingB2cComponent from "../services/Utils/LoadingB2c";
import AuthGuard from './AuthGuard';


/*

<Route path="/" element={<ModalTemplate visible={isModalVisible} onClose={() => setIsModalVisible(false)}/>} />
          // <Route path="/" element={<SlideoverComponent visible={isDrawerVisible} onClose={() => setIsDrawerVisible(false)}/>} />
          // <Route path="/" element={<CentredTemplateComponent />} />
          // <Route path="/" element={<CentredTemplateComponent />} />
          // <Route path="/" element={<DefaultTemplateComponent />} />
*/

function RequestWrapper() {
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');
  // Check if id parameter exists and render AgentRequest
  if (id) {
    return <AgentRequest id={id} />;
  } else {
    // Render RequestComponent as fallback
    return <RequestComponent />;
  }
}
function AuthWrapper(element) {
  return (
    <AuthGuard>
      {element}
    </AuthGuard>
  );
}
function NavigationRoutes() {
  const [isDrawerVisible, setIsDrawerVisible] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(true);


    return (
        <Router>
          <Routes>
          <Route path="/modal-layout" element={<ModalTemplate visible={isModalVisible} onClose={() => setIsModalVisible(false)}/>} />
           <Route path="/slide-layout" element={<SlideoverComponent visible={isDrawerVisible} onClose={() => setIsDrawerVisible(false)}/>} />
           <Route path="/center-layout" element={<CentredTemplateComponent />} />
           <Route path="/default" element={<DefaultTemplateComponent />} />
           <Route path="/flow" element={<TwoColumnsTemplateComponent />} />
           {/*<Route path="/" element={<Login />} />*/}
            <Route path="/" element={<LoadingB2cComponent />} />
            <Route path="/dashboard" element={AuthWrapper(<DashboardComponent/>)} />
            {/* <Route path="/agent-request" element={<AgentRequest />} /> */}
            <Route path="/success-registration" element={AuthWrapper(<RegisterSuccessComponent />)} />
            <Route path="/identity-verification" element={AuthWrapper(<IdentityVerificationComponent />)} />
            <Route path="/identity-questions" element={AuthWrapper(<QBAQuestionComponent />)} />
            <Route path="/idv-start" element={AuthWrapper(<IDVStartComponent/>)} />
            <Route path="/idv-status" element={AuthWrapper(<IDVStatusComponent />)} />
            <Route path="/ans-status" element={AuthWrapper(<WrongAnswerComponent />)} />
            <Route path="/idv-success" element={AuthWrapper(<IDVSuccessComponent />)} />
            <Route path="/idv-failed" element={AuthWrapper(<SecurityQuestionComponent />)} />
            <Route path="/poa-manual" element={AuthWrapper(<POAManualComponent />)} />
            <Route path="/poa-start" element={AuthWrapper(<POAStartComponent />)} />
            <Route path="/poa-success" element={AuthWrapper(<POASuccessComponent />)} />
            <Route path="/poa-upload" element={AuthWrapper(<POAUploadComponent/>)} />
            <Route path="/poa-update" element={AuthWrapper(<POAUpdateComponent/>)} />
            <Route path="/poa-search" element={AuthWrapper(<POASearchAddressComponent/>)} />
            {/* <Route path="/request" element={<RequestComponent />} /> */}
            <Route path="/loading" element={<LoadingComponent />} />
            {/* <Route path="/image-capture" element={<WebcamDemo />} /> */}
            <Route path="/image-capture" element={AuthWrapper(<FaceLivenessComponent />)} />
            <Route path="/register-consent" element={<RegisterConsentComponent />} />
            <Route path="/request" element={AuthWrapper(<RequestWrapper />)} />
            {/* <Route path="/request" element={<RequestComponent />} /> */}
            {/* <Route path="/request?id=CR-638507653605900222-b11f93" element={<AgentRequest />} /> */}
          </Routes>
        </Router>
      );
}

export default NavigationRoutes;
