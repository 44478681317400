import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Card } from "antd";
import BetterIDIcon from "../../assets/Icons/betterid-2.svg";
import CentredTemplateSuccessComponent from "../../Components/Templpate/CentredTemplateSuccessComponent";
import {
  getCustomerRequestFeatureTypeAPI,
  getCustomerRequestStatusAPI,
  getPeopleMobileApi,
  getPeopleSourceSystemIDApi,
  peopleCreateApi,
  PeoplePostData,
  sendConsentApi,
} from "../../services/apiService/api";
import { useLocation, useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { b2cPolicies, loginRequest } from "../../authConfig";
import { AccountInfo, EventType, InteractionStatus } from "@azure/msal-browser";
import { msalInstance } from "../../index";
import { DataContext } from "./DataContext";
import { Simulate } from "react-dom/test-utils";
import error = Simulate.error;
import ModalTemplate from "../../Components/Templpate/ModalTemplateComponent";
import Warning from "../../assets/Icons/warning.svg";
import { PrimaryButtonComponent } from "@betterhome/components";
interface LoadingComponentProps {}

const LoadingB2cComponent: FunctionComponent<LoadingComponentProps> = () => {
  const [apiData, setApiData] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const { userData, setUserData } = useContext(DataContext);

  const { instance } = useMsal();
  const { inProgress } = useMsal();
  const storedProfile = sessionStorage.getItem("userDataSession");

  if (storedProfile) {
    const userDataObj = JSON.parse(storedProfile);
  }

  const isAuthenticated = useIsAuthenticated();
  const [error, setError] = useState<string | null>(null);
  const [isAuthing, setisAuthing] = useState(false);

  const createPerson = async (apiData: any) => {
    try {
      const result = await peopleCreateApi(apiData);
      // Check if response is successful and contains data
      if (result && result.status == "Success") {
        const updatedProfile = { ...apiData, personId: result.resdata };
        //const updatedProfile = { ...userData, personId:'P-638519565116369310-f154d6' };
        setUserData(updatedProfile);
        sessionStorage.setItem(
          "userDataSession",
          JSON.stringify(updatedProfile)
        );
        return true;
      } else {
        const error = result.message ? result.message : "Something went wrong";
        setError(error);
        return false;
      }
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCustomerRequestStatusAPI();
        // Check if response is successful and contains data
        sessionStorage.setItem("customerRequestStatus", JSON.stringify(res));
        const resFeatureType = await getCustomerRequestFeatureTypeAPI();
        // Check if response is successful and contains data
        sessionStorage.setItem(
          "customerRequestFeatureType",
          JSON.stringify(resFeatureType)
        );
      } catch (error) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (isAuthing) {
      return;
    }
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).then((newAccount) => {
        let account = msalInstance.getActiveAccount();
        const updatedData = {
          ...userData,
          customerMobile: account?.idTokenClaims?.phoneNumber,
          customerEmail: account?.idTokenClaims?.email,
          givenName: account?.idTokenClaims?.given_name,
          familyName: account?.idTokenClaims?.family_name,
          SaId: account?.idTokenClaims?.extension_SAIDNumber,
          customerName:
            account?.idTokenClaims?.given_name +
            " " +
            account?.idTokenClaims?.family_name,
          user_B2C_ID: account?.localAccountId,
        };
        setUserData(updatedData);
        sessionStorage.setItem("userDataSession", JSON.stringify(updatedData));
        navigate("/register-id");
        //ToDo - Once Id is in B2c, make one post request here
      })
      .catch((error)=>{
      });
     
      console.log("Not authenticated");
     

    } else if (isAuthenticated) {
      setisAuthing(true);
      console.log("Authenticated");
      //debugger;
      let account = msalInstance.getActiveAccount();

      if (account?.idTokenClaims) {
        const updatedData = {
          ...userData,
          customerMobile: account?.idTokenClaims?.phoneNumber,
          customerEmail: account?.idTokenClaims?.email,
          givenName: account?.idTokenClaims?.given_name,
          SaId: account?.idTokenClaims?.extension_SAIDNumber,
          familyName: account?.idTokenClaims?.family_name,
          customerName:
            account?.idTokenClaims?.given_name +
            " " +
            account?.idTokenClaims?.family_name,
          user_B2C_ID: account?.localAccountId,
        };

        const requestuser = sessionStorage.getItem(
          "userReceivedRequestDataSession"
        );

        if (requestuser) {
          const requestuserObj = JSON.parse(requestuser);

          if (updatedData.customerEmail !== requestuserObj?.customerEmail) {
            msalInstance.clearCache()
            sessionStorage.clear();
            localStorage.clear();
            msalInstance.logoutRedirect({
              postLogoutRedirectUri: "/" // The URL to redirect after logout
            });
            return;
          }
        }
        setUserData(updatedData);
        sessionStorage.setItem("userDataSession", JSON.stringify(updatedData));
        loadLocalUserFromB2CId(account?.localAccountId).then(
          (res) => {
            if (res) {
              setisAuthing(false);
              navigate("dashboard");
            } else {
              // @ts-ignore
              const data = {
                idNumber: updatedData.SaId,
                givenName: updatedData.givenName,
                familyName: updatedData.familyName,
                identityDocumentTypeId: "1",
                isVerified: false,
                phoneNumber: (updatedData.customerMobile as string)?.replace(
                  "+",
                  ""
                ),
                sourceSystemId: updatedData.user_B2C_ID,
                phoneNumberTypeId: "1",
                emailAddress: updatedData.customerEmail
                  ? updatedData.customerEmail
                  : "",
                emailAddressTypeId: "1",
              };
              createPerson(data).then((res) => {
                setisAuthing(false);
                if(res){
                  navigate("/register-consent");
                }
              });
            }
          },
          (error) => {}
        );
      } else {
        if (account)
          loadLocalUserFromB2CId(account?.localAccountId).then((res) => {
            const customerDataString =
              sessionStorage.getItem("userDataSession");
            const customerData = JSON.parse(customerDataString ?? "{}");
            const requestuser = sessionStorage.getItem(
              "userReceivedRequestDataSession"
            );
            if (requestuser) {
              const requestuserObj = JSON.parse(requestuser);
              if (
                customerData.customerEmail !== requestuserObj?.customerEmail
              ) {
                msalInstance.clearCache();
                sessionStorage.clear();
                localStorage.clear();
                msalInstance.logoutRedirect({
                  postLogoutRedirectUri: "/", // The URL to redirect after logout
                });
                return;
              }
            }
            if (res) {
              navigate("dashboard");
            } else {
              msalInstance.clearCache()
              sessionStorage.clear();
              localStorage.clear();
              msalInstance.logoutRedirect({
                postLogoutRedirectUri: "/" // The URL to redirect after logout
              });
              setisAuthing(false);
            }
          });
      }
    }
    //console.log("old account info");
  });
  const loadLocalUserFromB2CId = async (azureID: string) => {
    try {
      const res = await getPeopleSourceSystemIDApi(azureID);
      if (res.status == 200) {
        if ("data" in res) {
          const customerDataString = sessionStorage.getItem("userDataSession");
          const customerData = JSON.parse(customerDataString ?? "{}");

          //debugger;
          const updatedUserData = {
            ...customerData,
            SaId: res.data.identityDocument.identityNumber,
            customerEmail: res.data.emailAddresses[0]?.value,
            customerMobile: res.data.phoneNumbers[0]?.value,
            customerName: res.data.name.customerName,
            familyName: res.data.name.familyName,
            givenName: res.data.name.givenName,
            personId: res.data.id,
            user_B2C_ID: res.data.sourceSystemId,
          };
          setUserData(updatedUserData);
          sessionStorage.setItem(
            "userDataSession",
            JSON.stringify(updatedUserData)
          );
        }
        localStorage.setItem("isAuthenticated", "true");
        return true;
      } else {
       
        // localStorage.setItem("isAuthenticated", "false");
        return false;
      }
    } catch (error) {
      // Handle error
    } finally {
    }
  };
  const onErrorClose = () => {
    setError(null);
    msalInstance.clearCache();
    sessionStorage.clear();
    localStorage.clear();
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: "/", // The URL to redirect after logout
    });
  };
  return (
    <CentredTemplateSuccessComponent>
      <div className="centered-content center-transparent-card">
        <div className="mb-m">
          <img src={BetterIDIcon} alt="Warning" />
        </div>
      </div>
      <ModalTemplate
        title=""
        visible={error ? true : false}
        onClose={onErrorClose}
      >
        <div className="mb-m">
          <img src={Warning} alt="Warning" />
        </div>
        <div className="verification-process">
          <h4 className="Text-Primary mb-m">Error</h4>
          <h4 className="BaseRegular mb-xl">{error}</h4>

          {/* <PrimaryButtonComponent
                    buttonText="Back to Dashboard"
                    isDisabled={false}
                    onClick={handleBackDashboard}
                    buttonSize={ButtonSizes.large}
                    buttonStyle={ButtonStyles.solid}
                    /> */}
        </div>
      </ModalTemplate>
    </CentredTemplateSuccessComponent>
  );
};

export default LoadingB2cComponent;
