import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import CentredTemplateSuccessComponent from '../../../../Components/Templpate/CentredTemplateSuccessComponent';
import check from '../../../../assets/Icons/check.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { isConstructorDeclaration } from 'typescript';
import { ButtonSizes, ButtonStyles, PrimaryButtonComponent } from "@betterhome/components";
import { updateCustomerRequestStatusAPI } from '../../../../services/apiService/api';

interface RegisterSuccessProps {
}


const RegisterSuccessComponent: React.FC<RegisterSuccessProps> = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isComplete, setIsComplete] = useState<boolean|null>();
  const [requestData, setRequestData] = useState<any[]>([]);
  const path = location.state.from
  const requestId = sessionStorage.getItem('requestId')

  useEffect(() => {
    if(path == "/register-component"){
      setIsComplete(true)
      const storedData = sessionStorage.getItem('userRequestData');
      if (storedData) {
        const reqData = JSON.parse(storedData);
        setRequestData(reqData);
      }
    }
    else{
      setIsComplete(false)
    }
  }, [path]);
  useEffect(() => {
    const isAuthenticated = localStorage.setItem('isAuthenticated', 'true');
    if(isComplete === false){
      setTimeout(() => {
        navigate("/dashboard")
      }, 3000)
    }
  }, [isComplete]);


  const getRequestorName = (id:any) => {
    const request = requestData.find(item => item.id === id);
    return request ? request.requestorInfo.name : "";
  };

  const handleBtnClick = async () => {
    const statusUpdate = await updateCustomerRequestStatusAPI('5',requestId)
    navigate("/dashboard")
  }

  return (
    <CentredTemplateSuccessComponent>
      <div className="centered-content center-transparent-card">
        {/* Card contents go here */}
        <div className='mb-m'>

          <img src={check} alt="verified" />

        </div>
       
          <div className='verification-contents'>
            {isComplete &&<h4 className='Text-Primary mb-xs'>Success!</h4>}
            <h5 className='BaseRegular mb-xl'>{isComplete ? `Your information has been shared with ${getRequestorName(requestId)}. They will be notified shortly.` : "Success, your BetterID account has been created!"}</h5>
            {isComplete &&<PrimaryButtonComponent
            buttonText="Back to Dashboard"
            isDisabled={false}
            onClick={handleBtnClick}
            buttonSize={ButtonSizes.large}
            buttonStyle={ButtonStyles.solid}
          />}
          </div>
        </div>

    </CentredTemplateSuccessComponent>
  );
};

export default RegisterSuccessComponent;

