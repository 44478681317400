import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import {
    ButtonSizes,
    PrimaryButtonComponent,
    ButtonStyles,
    RoundedButtonComponent,
    ButtonIcons
} from "@betterhome/components";
import VerifiedIcon from '../../../assets/Icons/verified.svg';
import LoaderImg from '../../../assets/Icons/Loader.png';
import CentredTemplateComponent from '../../../Components/Templpate/CentredTemplateComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import Text from "../IdentityVerification/Constants.json"
import { updateCustomerRequestFeatureStatusAPI } from '../../../services/apiService/api';


const IDVSuccessComponent = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [imageData,setImageData] = useState("")
    const [requestFeatureId,setRequestFeatureId] = useState("")
    const [requestFeatureData,setRequestFeatureData] = useState({})
    const requestIdData = sessionStorage.getItem("requestIdData");

    useEffect(() => {
        const storedProfile = sessionStorage.getItem("userDataSession");
        if (storedProfile) {
            const userProfileObj = JSON.parse(storedProfile);
            const image1 = userProfileObj.image;
            setImageData(image1)
        }
        if (requestIdData) {
            const requestDataObj = JSON.parse(requestIdData);
            const requestedFeature = requestDataObj.requestedFeature.find((feature:any) => feature.requestedFeatureType === 1);
            const requestedFeatureId = requestedFeature ? requestedFeature.id : null;
            setRequestFeatureId(requestedFeatureId)
        }
    }, []);

    const handleIDVComplete = async () => {
        const statusUpdate = await updateCustomerRequestFeatureStatusAPI('4',requestFeatureId)
        if(statusUpdate){
            if (requestIdData) {
                const requestDataObj = JSON.parse(requestIdData);
                requestDataObj.requestedFeature.forEach((feature:any) => {
                    if (feature.id === requestFeatureId) {
                        feature.requestedFeatureStatus = 4;
                    }
                });
                sessionStorage.setItem('requestIdData', JSON.stringify(requestDataObj));
            }
            navigate("/request")
        }
    };
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

    const image =  sessionStorage.getItem("idvImage")??"";

    const [success, setSuccess] = useState(true);
    const IDVSuccessText = Text.IDVSuccess
    return (
        <CentredTemplateComponent isLoggedin={isAuthenticated} >
            <Card className="page-card centered-content">
                <div className={`profile-container ml-xxl ${success ? 'mb-xxxl-profile' : 'mb-m'} ${success ? 'success' : ''}`}>
                    {success && <div><img src={LoaderImg} className="success-border-profile-loader" alt="LoaderImg"/></div>}
                    <img src={image ?? ""} className={`success-profile-image ${success ? 'success' : ''}`} alt="Agent Logo" />
                    <img src={VerifiedIcon} className='success-tick-icon' alt="Verified Icon" />
                </div>
                <div className="verification-process">
                    <h4 className='Text-Primary mb-xs'>{IDVSuccessText.successMessage}</h4>
                    <div className='verification-contents'>
                        <h4 className='BaseRegular mb-xl'>{IDVSuccessText.message}</h4>
                    </div>
                    <PrimaryButtonComponent
                        buttonText={IDVSuccessText.btnTextContinue}
                        isDisabled={false}
                        onClick={() => {
                            handleIDVComplete()

                        }}
                        buttonSize={ButtonSizes.large}
                        buttonStyle={ButtonStyles.solid}
                    />
                    {/* <RoundedButtonComponent
                        buttonIcon={ButtonIcons.CaretDown}
                        onClick={()=>{window.alert(sessionStorage.getItem("idvResponseData"))}}></RoundedButtonComponent> */}

                </div>
            </Card>
        </CentredTemplateComponent>

    );
};

export default IDVSuccessComponent;
