import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { ButtonSizes, PrimaryButtonComponent, ButtonStyles, Search } from "@betterhome/components";
import Location from '../../assets/Icons/location-map.svg';
import FlowTemplateComponent from '../../Components/Templpate/FlowTemplate';
import { MapPin } from "@phosphor-icons/react";
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { useNavigate } from 'react-router-dom';
import Text from "./Constants.json";

const POAStartComponent: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  const [address, setAddress] = useState<string>('');
  const [isReadOnly, setIsReadOnly] = useState(false);
  const POAStartComponentText = Text.POAStartComponent;

  useEffect(() => {
    const userAddress = sessionStorage.getItem("userAddress");
    if (userAddress) {
      const parsedAddress = JSON.parse(userAddress);
      const fullAddress = `${parsedAddress.addressLine1} ${parsedAddress.addressLine2}, ${parsedAddress.suburb}, ${parsedAddress.postalCode}`;
      setAddress(fullAddress);
      setIsReadOnly(true);
    }
  }, []);

  const btnConfirmAddressClicked = () => {
    navigate("/poa-manual");
  };

  const btnUpdateAddressClicked = () => {
    navigate("/poa-update");
  };

  const handleAddressChange = (value: string) => {
    setAddress(value);
  };

  const handleSelect = async (event: any) => {
    try {
      const results = await geocodeByAddress(event);
      setAddress(event);
      // You can also extract and use latitude/longitude if needed:
      // const latLng = await getLatLng(results[0]);
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  return (
    <FlowTemplateComponent isLoggedin={isAuthenticated}>
      <Card className="page-card centered-content">
        <div className="mb-m">
          <img src={Location} alt="Location" />
        </div>
        <div className="verification-process">
          <div className='verification-contents'> 
            <h4 className="Text-Primary mb-xl">
              {POAStartComponentText.title}
            </h4>
          </div>
          <div>
            <PlacesAutocomplete
              value={address}
              onChange={handleAddressChange}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <Search
                    {...getInputProps({
                      placeholder: "Search address",
                      searchType: "",
                      disabled: isReadOnly,
                    })}
                    searchedAddress={address}
                  />
                  {suggestions.length > 0 && (
                    <div className="transparent-card mt-s dropdown-address-card">
                      {loading && <div>{POAStartComponentText.textLoading}</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "dropdown-address-selected"
                          : "dropdown-address";

                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                            })}
                          >
                            <MapPin className='map-icon' weight="regular" size={20} />
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className="mt-xl">
            <PrimaryButtonComponent
              buttonText={POAStartComponentText.btnConfimText}
              
              isDisabled={false}
              onClick={btnConfirmAddressClicked}
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyles.solid}
            />
          </div>
          <div className="mt-m">
            <PrimaryButtonComponent
              buttonText={POAStartComponentText.btnUpdateText}
              isDisabled={false}
              onClick={btnUpdateAddressClicked}
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyles.transparent}
            />
          </div>
        </div>
      </Card>
    </FlowTemplateComponent>
  );
};

export default POAStartComponent;