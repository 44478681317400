import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import CentredTemplateSuccessComponent from '../../Components/Templpate/CentredTemplateSuccessComponent';
import Check from '../../assets/Icons/check.svg';
import { useNavigate } from 'react-router-dom';
import Text from "./Constants.json";
import { updateCustomerRequestFeatureStatusAPI } from '../../services/apiService/api';
interface POASuccessProps {
}


const POASuccessComponent: React.FC<POASuccessProps> = () => {
    const navigate = useNavigate(); 
    const POASuccessComponentText = Text.POASuccessComponent
    const requestId = sessionStorage.getItem('requestId')
    const [requestFeatureId,setRequestFeatureId] = useState("")
    const requestIdData = sessionStorage.getItem("requestIdData");

  const handlePOAComplete = async () => {
      const statusUpdate = await updateCustomerRequestFeatureStatusAPI('4',requestFeatureId)
      if(statusUpdate){
        if (requestIdData) {
          const requestDataObj = JSON.parse(requestIdData);
          requestDataObj.requestedFeature.forEach((feature:any) => {
              if (feature.id === requestFeatureId) {
                  feature.requestedFeatureStatus = 4;
              }
          });
          sessionStorage.setItem('requestIdData', JSON.stringify(requestDataObj));
      }
      navigate("/request");
        // setTimeout(() => {
        // }, 3000); // 3000 milliseconds = 3 seconds
      }
  };

  useEffect(() => {
    if (requestIdData) {
      const requestDataObj = JSON.parse(requestIdData);
      const requestedFeature = requestDataObj.requestedFeature.find((feature:any) => feature.requestedFeatureType === 2);
      const requestedFeatureId = requestedFeature ? requestedFeature.id : null;
      setRequestFeatureId(requestedFeatureId)
    }
  }, []);

  useEffect(() => {
    // Redirect after 3 seconds
    if(requestFeatureId)
      handlePOAComplete()
    // const redirectTimeout = setTimeout(() => {
    //   const isPoaValidReq = sessionStorage.getItem('isPoaValidReq')
    //   if(isPoaValidReq){
    //     //sessionStorage.setItem('isPoaValidReq',JSON.stringify({"reqId": requestId }))
    //   }
    //   const existingIsPoaValidReqJSON = sessionStorage.getItem('isPoaValidReq');
    //   if (!existingIsPoaValidReqJSON) {
    //       sessionStorage.setItem('isPoaValidReq', JSON.stringify([requestId]));
    //   }
    //   else{
    //       let existingIsPoaValidReq = [];
    //       existingIsPoaValidReq = JSON.parse(existingIsPoaValidReqJSON);
    //       if (!existingIsPoaValidReq.includes(requestId)) {
    //           // If not present, push the ID into the array
    //           existingIsPoaValidReq.push(requestId);
    //       }
    //       sessionStorage.setItem('isPoaValidReq', JSON.stringify(existingIsPoaValidReq));
    //   }
    //   navigate("/request")
    // }, 30000);
    // return () => clearTimeout(redirectTimeout);
  }, [requestFeatureId]);

  return (
    <CentredTemplateSuccessComponent>
      <div className="centered-content center-transparent-card">
          {/* Card contents go here */}
          <div className='mb-m'>

            <img src={Check} alt="Check" />

          </div>
          <div className="verification-process">
            <div className='verification-contents'>
              <h5 className='LargeRegular mb-xl'>{POASuccessComponentText.title}</h5>
            </div>
          </div>
        </div>
      </CentredTemplateSuccessComponent>
  );
};

export default POASuccessComponent;
