import React, { useEffect, useState } from 'react';
import { Radio , Spin} from 'antd';
import { ButtonSizes, ButtonStyles, PrimaryButtonComponent, RadioSelectionComponent, TextInputComponent } from "@betterhome/components";
import FlowTemplateComponent from '../../../Components/Templpate/FlowTemplate';
import { useNavigate} from 'react-router-dom';
import { getSecurityQuestionsAPI, postSubmitAnswer } from '../../../services/apiService/api';
import ModalTemplate from '../../../Components/Templpate/ModalTemplateComponent';
import Warning from '../../../assets/Icons/warning.svg';

interface QBAQuestionProps {}

interface OptionData {
    option_number: number;
    optionText: string;
}

interface QuestionData {
    question_number: number;
    question_text: string;
    option_data: OptionData[];
}

interface SelectedValues {
    transaction_id?: string | number;
    [key: number]: string;
}

const QBAQuestionComponent: React.FC<QBAQuestionProps> = () => {
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
    const [questions, setQuestions] = useState<QuestionData[]>([]);
    const [selectedValues, setSelectedValues] = useState<{
        [x: string]: string | number; [key: number]: string 
}>({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [error, setError] = useState("");
    const [inputText, setInputText] = useState("");

    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const mapSelectedValuesToOptionNumbers = () => {
        const mappedValues: { [key: string]: number | string } = {
            transaction_id: selectedValues.transaction_id
        };
        questions.forEach((question) => {
            const selectedValue = selectedValues[question.question_number];
            if (question.option_data.length === 0) {
                // If there are no options, use the input text
                mappedValues[`question_option${question.question_number}`] = selectedValue;
            } else {
                // Find the option number that corresponds to the selected text
                const selectedOption = question.option_data.find(option => option.optionText === selectedValue);
                if (selectedOption) {
                    mappedValues[`question_option${question.question_number}`] = selectedOption.option_number;
                } else {
                    mappedValues[`question_option${question.question_number}`] = 0;
                }
            }
        });
        return mappedValues;
    };

    const handleFinalSubmit = async () => {
        const mappedValues = mapSelectedValuesToOptionNumbers();
        setLoading(true);
        const response = await postSubmitAnswer(mappedValues);
        if (response.status === "error") {
            setLoading(false);
            setError(response.message);
            setIsModalVisible(true);
        } else {
            setLoading(false);
            navigate("/idv-success");
        }
    };

    const handleRadioChange = (optionNumber:any) => {
        if (!questions){
            return;
        }
        const questionNumber = questions[currentQuestionIndex].question_number;
        setSelectedValues((prev) => ({
            ...prev,
            [questionNumber]: optionNumber.toString(),
        }));
        // Move to the next question or navigate to the final route if last question
        if (currentQuestionIndex < questions.length - 1) {
            setTimeout(() => {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            }, 100);
        } else {
            handleFinalSubmit();
        }
    };

    const onBackClick = () => {
        navigate(-1);
    };
    const updateInputText = (inputText: string) => {
        setInputText(inputText);
        const questionNumber = questions[currentQuestionIndex].question_number;
        setSelectedValues((prev) => ({
            ...prev,
            [questionNumber]: inputText,
        }));
      };

    useEffect(() => {
        const fetchData = async (personId:string) => {
            try {
                const res: any  = await getSecurityQuestionsAPI(personId);
                if(res.status === 'error'){
                    const error1 = "Something went wrong. Please try again later";
                    setError(error1);
                    setIsModalVisible(true);
                }
                else {
                    if('return_data' in res){
                        setIsModalVisible(false);
                        if(res.return_data.knock_out_rule_triggered === "Soft locked" && res.return_data.question_data == null ){
                            navigate("/ans-status", { "state": { "error": "maxAttempts" } });
                        }
                        // Set transaction ID to selected values
                        setSelectedValues(prev => ({ ...prev, transaction_id: res.return_data.transaction_id }));
                        setQuestions(res.return_data.question_data);
                    }
                }
            } catch (error) {
            }
        };
        fetchData("test");
        //Uncomment after api integration
        // if(storedProfile){
        //     const userDataObj = JSON.parse(storedProfile);
        //     const personId = userDataObj.personId
        //     console.log("personId---------",personId)
        //     fetchData(personId);
        // }
        // else{
        //     const error1 = "Something went wrong. Please try again later"
        //     setError(error1)
        //     setIsModalVisible(true)
        // }
    }, []);

    const handleBackDashboard = () => {
        setIsModalVisible(false);
        navigate("/dashboard");
    };
    const handleSubmit = () => {
        setSelectedValues((prev) => ({
            ...prev,
            [questions[currentQuestionIndex].question_number]: inputText,
        }));
        if (currentQuestionIndex < questions.length - 1) {
            // Clear the input for the next question
            setInputText('');
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            handleFinalSubmit();
        }
    };

    return (
        <FlowTemplateComponent isLoggedin={isAuthenticated}>
            {loading ? (
                <Spin size="small" />
            ) : (
                <>
                    {questions.length > 0 && (
                        <div className='page-container centered-content'>
                            <div className='verification-contents mb-xl mt-xl'>
                                <h5 className='Text-Primary'>{questions[currentQuestionIndex].question_text}</h5>
                            </div>
                            <div className="Fields">
                                <Radio.Group value={selectedValues[questions[currentQuestionIndex].question_number]} onChange={(e) => handleRadioChange(e.target.value)}>
                                    { questions[currentQuestionIndex].option_data.length === 0 ? (
                                        <div className="flexInput"><TextInputComponent
                                            label="Enter your answer"
                                            name=""
                                            value={inputText}
                                            onChange={updateInputText}
                                        ></TextInputComponent><div className='mt-xs'>
                                                <PrimaryButtonComponent
                                                    buttonText="Submit"
                                                    isDisabled={false}
                                                    onClick={handleSubmit}
                                                    buttonSize={ButtonSizes.large}
                                                    buttonStyle={ButtonStyles.solid} />
                                            </div></div>):
                                        (questions[currentQuestionIndex].option_data.map(option => (
                                        <div
                                            className="Selection-Lists"
                                            key={option.option_number}
                                            onClick={() => handleRadioChange(option.optionText)}
                                        >
                                            <RadioSelectionComponent
                                                label={option.optionText}
                                                value={option.optionText}
                                                checked={selectedValues[questions[currentQuestionIndex].question_number] === option.option_number.toString()}
                                                onChange={() => handleRadioChange(option.option_number)}
                                                className="default"
                                                radiosizes="Large"
                                            />
                                        </div>
                                    )))}
                                </Radio.Group>
                            </div>
                            <div className='desktopshow mt-xs'>
                                <PrimaryButtonComponent
                                    buttonText="Back"
                                    isDisabled={false}
                                    onClick={onBackClick}
                                    buttonSize={ButtonSizes.large}
                                    buttonStyle={ButtonStyles.transparent}
                                />
                            </div>
                        </div>
                    )}
                </>)}
            <ModalTemplate title="" visible={isModalVisible} onClose={handleBackDashboard}>
                <div className='mb-m'>
                    <img src={Warning} alt="Warning" />
                </div>
                <div className="verification-process">
                    <h4 className='Text-Primary mb-m'>Error</h4>
                    <h4 className='BaseRegular mb-xl'>{error}</h4>

                    <PrimaryButtonComponent
                    buttonText="Back to Dashboard"
                    isDisabled={false}
                    onClick={handleBackDashboard}
                    buttonSize={ButtonSizes.large}
                    buttonStyle={ButtonStyles.solid}
                    />

                </div>
                </ModalTemplate>
        </FlowTemplateComponent>
    );
};

export default QBAQuestionComponent;
