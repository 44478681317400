import React, { useState } from 'react';
import { Card, Divider, Progress } from 'antd';
import { X, Dot, Trash, ArrowsClockwise } from "@phosphor-icons/react";
import CheckSolid from '../../assets/Icons/check-circle-solid.svg';
import { ButtonSizes, PrimaryButtonComponent, ButtonStyles, Search, FileInputComponent, FormSelectComponent, HighlightMessagesComponent, MessageType } from "@betterhome/components";
import House from '../../assets/Icons/house.svg';
import CheckCircle from '../../assets/Icons/check-circle.svg';
import FlowTemplateComponent from '../../Components/Templpate/FlowTemplate';
import { useNavigate } from 'react-router-dom';
import Text from "./Constants.json";

interface POAUploadProps {
}

const POAUploadComponent: React.FC<POAUploadProps> = () => {

  interface VerificationStepProps {
    icon: React.ReactNode;
    title: string;
  }
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  const navigate = useNavigate();
  const [fileName, setFileName] = useState('');
  const [isFileSelected, setIsFileSelected] = useState(false);
  const POAUploadComponentText = Text.POAUploadComponent
  const handleFileChange = (file: File) => {
    setFileName(file.name);
    setIsFileSelected(true);
  };

  const btnContinueClicked = () => {
    navigate("/poa-success");
  };

  const [selectedOptionValue, setSelectedOptionValue] = useState<string>('');

  const handleSelectChange = (value: string) => {
    setSelectedOptionValue(value);
  };

  const options = [
    { value: 'UB', label: 'Utility Bill' },
    { value: 'MI', label: 'Municipal invoice' },
    { value: 'BS', label: 'Bank statement' },
    { value: 'TL', label: 'TV license' },
    { value: 'ID', label: 'Insurance documents' },
    { value: 'OD', label: 'Official tax document' }
  ];

  const VerificationStep: React.FC<VerificationStepProps> = ({ icon, title }) => {
    return (
      <div className="verification-step mt-5 mb-5" style={{ textAlign: 'left' }}>
        <div className="step-icon check-margin">{icon}</div>
        <div className="SmallRegular">{title}</div>
      </div>
    );
  };

  return (
    <div className='poa-upload'>
      <FlowTemplateComponent isLoggedin={isAuthenticated}>
        <Card className="page-card centered-content">
          <div className='mb-m'>

            <img src={House} alt="House" />

          </div>
          <div className="verification-process">
            <div className='verification-contents'>
              <h4 className='Text-Primary mb-m'>{POAUploadComponentText.title}</h4>
              <h4 className="BaseRegular mb-m">{POAUploadComponentText.subTitle}</h4>
            </div>
            {/* <div className='transparent-card'>
              <span className="SmallRegular">
                <span className="Title">{POAUploadComponentText.spanText1}</span>
                {POAUploadComponentText.spanText2}
              </span>
              <Divider className="verification-divider" />
              <VerificationStep
                icon={<img src={CheckCircle} alt="CheckCircle" />}
                title={POAUploadComponentText.verificationStep1Title}
              />
              <VerificationStep
                icon={<img src={CheckCircle} alt="CheckCircle" />}
                title={POAUploadComponentText.verificationStep2Title}
              />
            </div> */}
            <div className='small-msgbox mb-xs'>
              <HighlightMessagesComponent
                Title=""
                Textmsg="The document must not be older than 3 months"
                messageType={MessageType.brand}
                closeIcon={null}
              ></HighlightMessagesComponent>
            </div>
            <div className='small-msgbox mb-xl'>
              <HighlightMessagesComponent
                Title=""
                Textmsg="Your name and address must be displayed on the document"
                messageType={MessageType.brand}
                closeIcon={null}
              ></HighlightMessagesComponent>
            </div>
           
              <FormSelectComponent
                label="Document Type"
                options={options}
                onChange={handleSelectChange}
              ></FormSelectComponent>
           
              <FileInputComponent fileName={fileName} onChange={handleFileChange} />
              {/* <Card className="mobile-card mb-m"> */}
                {/* <div id='loading'>
                  <span className="Titlepdf BaseRegular carditems">
                    municipal-invoice.pdf
                  </span>
                  <span className='carditems mt-xs'>
                    <Progress percent={50} showInfo={false} />
                    <X className='closeicon' weight="bold" />
                  </span>

                  <span className="TinyRegular protext carditemsleft">
                    567KB   <Dot size={32} weight="bold" style={{ marginLeft: '-16px' }} /> <span style={{ marginLeft: '-16px' }}>30% uploaded</span>
                  </span>
                </div> */}

                {/* <div id='filled'>
                  <span className="Titlepdf BaseRegular carditems">
                    <span className='imgtitle'><img src={CheckSolid} alt="CheckSolid" /> municipal-invoice.pdf</span>
                    <span className='imgtitle'><Trash color='var(--indicator-error)' className='closeicon' weight="bold" /></span>
                  </span>

                  <span className="TinyRegular protext carditemsleft">
                    567KB   <Dot size={32} weight="bold" style={{ marginLeft: '-16px' }} /> <span style={{ marginLeft: '-16px' }}>30% uploaded</span>
                  </span>
                </div> */}

                {/* <div id='error'>
                  <span className="Titlepdf BaseRegular carditems">
                    municipal-invoice.pdf
                   <ArrowsClockwise  className='closeicon' weight="bold" />
                  </span>

                  <span className="ant-typography-danger">
                     Oops! Upload failed
                  </span>
                </div> */}

                {/* <div id='validation'>
                  <span className="Titlepdf BaseRegular carditems">
                    municipal-invoice.pdf
                  </span>

                  <span className="ant-typography-danger">
                  Only .jpg, .png and .pdf files are permitted
                  </span>
                </div> */}

              {/* </Card> */}

            <div className='mt-xs mb-m'>
              <PrimaryButtonComponent
                buttonText={POAUploadComponentText.btnContinueText}
                isDisabled={!isFileSelected}
                onClick={btnContinueClicked}
                buttonSize={ButtonSizes.large}
                buttonStyle={ButtonStyles.solid}
              />
            </div>
          </div>
        </Card>
      </FlowTemplateComponent>
    </div>
  );
};

export default POAUploadComponent;