import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { ButtonSizes, PrimaryButtonComponent, ButtonStyles, Search } from "@betterhome/components";
import Location from '../../assets/Icons/location-map.svg';
import FlowTemplateComponent from '../../Components/Templpate/FlowTemplate';
import { MapPin } from "@phosphor-icons/react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useNavigate } from 'react-router-dom';
import Text from "./Constants.json";

interface POAUpdateProps { }

const POAUpdateComponent: React.FC<POAUpdateProps> = () => {
  const [address, setAddress] = useState("");
  const navigate = useNavigate();
  const POAUpdateComponentText = Text.POAUpdateComponent
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  // const btnManualsClicked = () => {
  //   navigate("/poa-search");
  // };

  const btnManualsClicked = () => {
    sessionStorage.setItem("userAddMain","")
    sessionStorage.setItem("userAddress","")
    navigate("/poa-manual");
  };

  // const btnUpdateAddressClicked = () => {
  //   // navigate("/poa-update");
  //   navigate("/poa-start", { state: { address } });
  // };

  const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);
  
    useEffect(() => {
      const checkIsMobile = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);
  
      return () => window.removeEventListener('resize', checkIsMobile);
    }, []);
  
    return isMobile;
  };
  const isMobile = useIsMobile();

  const handleAddressChange = (value: string) => {
    setAddress(value);
  };

  const handleSelect = async (event: any) => {
    setAddress(event);
    try {
      const results = await geocodeByAddress(event);
      sessionStorage.setItem("userAddMain",results[0].formatted_address)
      const addressComponents = results[0].address_components;

      let addressLine1 = '';
      let addressLine2 = '';
      let suburb = '';
      let postalCode = '';
      addressComponents.forEach(component => {
        if (component.types.includes('street_number')) {
          addressLine1 = component.long_name + ' ' + addressLine1;
        }
        if (component.types.includes('route')) {
          addressLine1 += component.long_name;
        }
        if (component.types.includes('sublocality') || component.types.includes('neighborhood')) {
          addressLine2 = component.long_name;
        }
        if (component.types.includes('locality')) {
          suburb = component.long_name;
        }
        if (component.types.includes('postal_code')) {
          postalCode = component.long_name;
        }
      });
      // Create JSON object
      let addressJSON = {
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        suburb: suburb,
        postalCode: postalCode
      };
      sessionStorage.setItem("userAddress", JSON.stringify(addressJSON));
      navigate("/poa-manual"); // Navigate to /poa-manual after selecting the address
            // const latLng = await getLatLng();
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  const isValidDetails = () => {
    return (address.length > 0)
  };

  const handleFocus = () => {
    if (isMobile) {
      navigate("/poa-search");
    }
  };

  const searchOptions = {
    componentRestrictions: { country: 'za' }  // 'za' is the country code for South Africa
  };

  return (
    <div>
      <FlowTemplateComponent isLoggedin={isAuthenticated}>
        <Card className="page-card centered-content">
          <div className="mb-m">
            <img src={Location} alt="Location" />
          </div>
          <div className="verification-process">
          <div className='verification-contents'> 
              <h4 className="Text-Primary mb-xl">
                {" "}
                {POAUpdateComponentText.title}
              </h4>
            </div>
            <div className="mb-xl">
              <PlacesAutocomplete
                value={address}
                onChange={handleAddressChange}
                onSelect={handleSelect}
                searchOptions={searchOptions}  // Pass the searchOptions here
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div onFocus={handleFocus}>
                    <Search
                      {...getInputProps({
                        placeholder: "Search address",
                        searchType: "",
                      })}
                      searchedAddress={address}
                    />
                    {suggestions.length > 0 && (
                      <div className="transparent-card mt-s dropdown-address-card">
                        {loading && <div>{POAUpdateComponentText.textLoading}</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "dropdown-address-selected"
                            : "dropdown-address";

                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                              })}
                            >
                              <MapPin className='map-icon' weight="regular" size={20} />
                              {suggestion.description}
                            </div>
                          );
                        })}
                        <div className='gray-btn mt-m'>
                          <PrimaryButtonComponent
                            buttonText={POAUpdateComponentText.btnText1}
                            isDisabled={false}
                            onClick={btnManualsClicked}
                            buttonSize={ButtonSizes.medium}
                            buttonStyle={ButtonStyles.solid}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            <div className='desktopshow'>
              <PrimaryButtonComponent
                buttonText={POAUpdateComponentText.btnText}
                isDisabled={false}
                onClick={btnManualsClicked}
                buttonSize={ButtonSizes.large}
                buttonStyle={ButtonStyles.solid}
              />
            </div>
          </div>
        </Card>
      </FlowTemplateComponent>
    </div>
  );
};

export default POAUpdateComponent;
