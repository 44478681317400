import React, { FunctionComponent,useEffect,useState } from 'react';
import { Card } from 'antd';
import BetterIDIcon from '../../assets/Icons/betterid-2.svg';
import CentredTemplateSuccessComponent from '../../Components/Templpate/CentredTemplateSuccessComponent';
import { getCustomerRequestStatusAPI, sendConsentApi } from "../../services/apiService/api";
import { useLocation, useNavigate } from 'react-router-dom';

interface LoadingComponentProps {

}

const LoadingComponent: FunctionComponent<LoadingComponentProps> = () => {
    const [apiData, setApiData] = useState();
    const navigate = useNavigate()
    const location = useLocation()
    const path = location.state.from

    useEffect(() => {
        const storedProfile = sessionStorage.getItem("userDataSession");
        if (storedProfile) {
            const userDataObj = JSON.parse(storedProfile);
            const personId = userDataObj.personId
            setApiData(personId)
        }
        if (path == "/register-component"){
            const timeout = setTimeout(() => {
                navigate("/success-registration", { "state": { "from": "/register-component" } })
            }, 2000);
        }
        
    }, []);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const res = await getCustomerRequestStatusAPI();
            // Check if response is successful and contains data
            sessionStorage.setItem("customerRequestStatus",JSON.stringify(res))
          } catch (error) {
    
          }
        };
        fetchData();
      }, []);


    useEffect(() => {
        const fetchData = async (data: any) => {
            if (data) {
                try {
                    const result = await sendConsentApi(data);  
                   // const result = {"status" : "Success"}
                    // Check if response is successful and contains data
                    if (result && result == 201) {
                        navigate("/success-registration", { "state": { "from": "/register-consent" } });
                    } 
                } catch (error) {
                }
            }
        };
        if (apiData && path === '/register-consent' ) {
            fetchData(apiData);
        }
    },[apiData,path])
    
    return (
        <CentredTemplateSuccessComponent>
            <div className="centered-content center-transparent-card">
                <div className='mb-m'>
                    <img src={BetterIDIcon} alt="Warning" />
                </div>
            </div>
        </CentredTemplateSuccessComponent>
    );
}

export default LoadingComponent;