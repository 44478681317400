// RegisterConsentComponent.jsx
import React, { useState,useEffect,useContext } from 'react';
import { Card, Divider, Checkbox} from 'antd';
import { ButtonSizes, PrimaryButtonComponent, ButtonStyles } from "@betterhome/components";
import FlowTemplateComponent from '../../../../Components/Templpate/FlowTemplate';
import BetterIDIcon from '../../../../assets/Icons/betterid-2.svg';
import Bank from '../../../../assets/Icons/bank.svg';
import Users from '../../../../assets/Icons/users.svg';
import HouseSell from '../../../../assets/Icons/house-sell-to-buy.svg';
import LifeCover from '../../../../assets/Icons/life-cover.svg';
import { useNavigate } from 'react-router-dom';
import { peopleCreateApi } from "../../../../services/apiService/api";
import { DataContext } from "../../../../services/Utils/DataContext";
//import { getCustomerRequestStatusAPI } from '../../../../services/apiService/api';
import {useLoading} from "../../../../services/Utils/LoadingContext";

interface RegisterConsentProps {
}

const RegisterConsentComponent: React.FC<RegisterConsentProps> = () => {
const [isChecked, setIsChecked] = useState(false);
const isAuthenticated = localStorage.getItem("isAuthenticated") === "true" ?  true : false;
const navigate = useNavigate()
  interface VerificationStepProps {
    icon: React.ReactNode;
    title: string;
    subtitle: string;
  }

  const handleCheckboxChange = (e: any) => {
      setIsChecked(e.target.checked);
  };

  const btnContinueClicked = () => {
    navigate("/loading", { "state": { "from": "/register-consent" } })
  }

  const VerificationStep: React.FC<VerificationStepProps> = ({ icon, title, subtitle }) => {
    return (
      <div className="verification-step">
        <div className="step-icon">{icon}</div>
        <div className='left-content'>
          <div className="BaseRegular">{title}</div>
          <div className="TinyRegular Text-Subtitle">{subtitle}</div>
        </div>
      </div>
    );
  };

  return (
    <FlowTemplateComponent hideLogo={true} isLoggedin={isAuthenticated}>
        <Card className="page-card centered-content">
          
          <div className='mb-m'>

            <img src={BetterIDIcon} alt="Better ID Icon" />

          </div>

          <div className="verification-process">
            <h4 className='Text-Primary mb-xl'>Welcome to BetterID</h4>
            <VerificationStep
              icon={<img src={Bank} alt="Bank" />}
              title="Safe & Secure"
              subtitle="Safely store your personal information"
            />
            <Divider className="verification-divider" />
            <VerificationStep
              icon={<img src={Users} alt="Users" />}
              title="Stay in control"
              subtitle="Manage who has access to your data"
            />
            <Divider className="verification-divider" />
            <VerificationStep
              icon={<img src={HouseSell} alt="House Sell" />}
              title="Save time!"
              subtitle="Reuse your information for all applications"
            />
            <Divider className="verification-divider" />
            <VerificationStep
              icon={<img src={LifeCover} alt="Life Cover" />}
              title="No hidden costs!"
              subtitle="BetterID is free for personal use"
            />
            <div className='Field mt-xl'>
            <Checkbox checked={isChecked} onChange={handleCheckboxChange}> </Checkbox>
            <span className="BaseRegular">
              I agree to BetterID’s <a href='#'>Privacy Policy</a> & <a href='#'>Terms of Use</a>.
            </span>
            </div>
            <div className='mt-xl'>
              <PrimaryButtonComponent
                buttonText="Continue"
                isDisabled={!isChecked}
                onClick={btnContinueClicked}
                buttonSize={ButtonSizes.large}
                buttonStyle={ButtonStyles.solid}
              />
            </div>
          </div>
        </Card>
     </FlowTemplateComponent>
  );
};

export default RegisterConsentComponent;

