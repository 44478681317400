import React, { useState, useEffect,useContext } from 'react';
import { Card, Divider,Spin } from 'antd';
import { ButtonSizes, ButtonIcons, PrimaryButtonComponent, ButtonStyles, StackedButtonComponent } from "@betterhome/components";
import { EnvelopeSimple, Phone, DeviceMobileCamera,CaretRight} from "@phosphor-icons/react";
import Location from '../../../assets/Icons/location-map.svg';
import Passport from '../../../assets/Icons/passport.svg';
import LocationDisabled from '../../../assets/Icons/location-house-disabled.svg';
import PassportDisabled from '../../../assets/Icons/passport-disabled.svg';
import circle from '../../../assets/Icons/circle.svg';
import BetterIDLogo from '../../../assets/Icons/betterid.svg';
import CheckSolid from '../../../assets/Icons/check-circle-solid.svg';
import Warning from '../../../assets/Icons/warning.svg';
import ChasEveritt from '../../../assets/Icons/default-chas-everitt.svg'
import ModalTemplate from '../../../Components/Templpate/ModalTemplateComponent';
import SlideoverComponent from '../../../Components/Templpate/SlideoverTemplateComponent';
//import DefaultTemplateBeforeLoginComponent from '../../../Components/Templpate/DefaultTemplate';
import FlowTemplateComponent from '../../../Components/Templpate/FlowTemplate';
import { useLocation, useNavigate } from 'react-router-dom';
import {getCustomerRequestByCustometId,updateCustomerRequestStatusAPI} from '../../../services/apiService/api'
import { DataContext } from '../../../services/Utils/DataContext'

const AgentRequest = () => {

  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  interface VerificationStepProps {
    icon: React.ReactNode;
    title: string;
    suffixicon?: React.ReactNode;
  }

  const VerificationStep: React.FC<VerificationStepProps> = ({ icon, title, suffixicon }) => {

    return (
      <div className="verification-step" style={{ padding: '0px 0px', justifyContent: 'space-between' }}>
        <div className="step-icon">{icon}</div>
        <div className="BaseRegular">{title}</div>
        <div className="suffix-icon">{suffixicon}</div>
      </div>
    );
  };
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState(false);
  //const storedIsValidData = sessionStorage.getItem('isValidData') === 'true';
  const location = useLocation()
  const [requestDataFromId ,setRequestDataFromId] = useState<any>();
  const { userData, setUserData } = useContext(DataContext);
  const [loading, setLoading] = useState<boolean>(true); // Initialize loading state
  let requestorName: string = '';
  let requestorImage: string = ''; 
  let showIdv = true;
  let showPoa = true;

  const searchParams = new URLSearchParams(location.search);
  const reqId = searchParams.get('id');
  let fccNumber = ""

    useEffect(() => {
      const fetchData = async () => {
        if (reqId) {
          try {
            const res = await getCustomerRequestByCustometId(reqId);
            if('data' in res){
              const apiData = res?.data
              requestorName = apiData?.requestorInfo.name || ""
              requestorImage = apiData?.requestorInfo.image || "";
              fccNumber = apiData?.requestorInfo.fccNumber || ""
              let customerEmail = apiData?.customerEmail
              let customerName = apiData?.customerName
              let customerMobile = apiData?.customerMobile
              const updatedProfile = { ...userData, customerEmail: customerEmail,customerName:customerName,customerMobile:customerMobile };
              setRequestDataFromId(apiData)
              setUserData(updatedProfile);
              sessionStorage.setItem("userReceivedRequestDataSession", JSON.stringify(updatedProfile));

            }
          } catch (error) {
            // Handle error
            //navigate("/")
          } 
        }
      };
      fetchData();
    }, []);

    useEffect(() => {
        if(requestDataFromId){
            setLoading(false)
        }
      }, [requestDataFromId]);

  

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isSlideoverVisible, setIsSlideoverVisible] = useState(false);

  // const handleOpenSlideover = () => {
  //   setIsSlideoverVisible(true);
  // };

  const handleCloseSlideover = () => {
   // setIsSlideoverVisible(false);
  };

  const handleDecline = async () => {
    // setIsSlideoverVisible(false);
    const statusUpdate = await updateCustomerRequestStatusAPI('3',reqId)
    navigate("/dashboard")
   };
  const handleBtnClick = () => {
    navigate("/");
  }

   // Helper function to get VerificationStep based on feature type
   const getVerificationStep = (type: any, feature: any) => {
    switch (type) {
      case 1:
        const RFIDVstatus = feature.requestedFeatureStatus == 4
        return (
          <VerificationStep
            icon={RFIDVstatus ? <img src={Passport} alt="Passport" /> : <img src={PassportDisabled} alt="Passport" />}
            title="Identity Verification"
            suffixicon={RFIDVstatus ? <img src={CheckSolid} alt="CheckSolid" /> : <img src={circle} alt="Circle" />}
          />
        );
      case 2:
        const RFPOAstatus = feature.requestedFeatureStatus == 4
        return (
          <VerificationStep
            icon={RFPOAstatus ? <img src={Location} alt="Location" /> : <img src={LocationDisabled} alt="Location" />}
            title="Proof of Residence"
            suffixicon={RFPOAstatus ? <img src={CheckSolid} alt="CheckSolid" /> : <img src={circle} alt="Circle" />}
          />
        );
      case 3:
        const RFAMLstatus = feature.requestedFeatureStatus == 4
        return (
          <VerificationStep
            icon={RFAMLstatus ? <img src={Location} alt="Other" /> : <img src={LocationDisabled} alt="Other" />}
            title="AML"
            suffixicon={RFAMLstatus ? <img src={CheckSolid} alt="CheckSolid" /> : <img src={circle} alt="Circle" />}
          />
        );
      default:
        return null;
    }
  };
  return (
    <FlowTemplateComponent hideCaretLeft={true}>
      {loading ? ( // Display loading indicator while loading
            <Spin size="small" />
          ) : (<Card className="page-card centered-content">
        <div className='profile-container mb-m'>

        {requestDataFromId.requestorInfo.profileImage && <img src={requestDataFromId.requestorInfo.profileImage || ""} className='agent-profile-image' alt="Agent Image" />}
        {requestDataFromId.requestorInfo.company.logo  && <img src={requestDataFromId.requestorInfo.company.logo || ""} className={requestDataFromId.requestorInfo.profileImage? 'agent-agency-logo':'agent-agency-logo-single'} alt="Company Logo" />}

        </div>
        <div className="verification-process">
          <h4 className='Text-Primary'>{requestDataFromId.requestorInfo.firstName || ""}</h4>
          <h5 className='SmallRegular Text-Subtitle mt-xxs mb-xxs'>{requestDataFromId.requestorInfo.jobTitle}</h5>
          <h6 className='TinyRegular Text-Subtitle'>FFC: {requestDataFromId.requestorInfo.company.ffcNumber}</h6>
          <div className='verification-contents'>
            <h4 className='BaseRegular vert-spacing-xl'>{requestDataFromId.requestorInfo.firstName || ""} has requested the following information:</h4>
          </div>
          {requestDataFromId?.requestedFeature?.map((feature:any, index:any) => {
            return (
              <Card
                key={feature.id}
                className={`selection-card ${feature.requestedFeatureStatus === 4 ? "success" : ""} mb-xs`}
              >
                {getVerificationStep(feature.requestedFeatureType, feature)}
              </Card>
            );
          })}
          <div className='mt-xl'>
            <PrimaryButtonComponent
              buttonText="Continue"
              isDisabled={false}
              onClick={handleBtnClick}
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyles.solid}

            />

          </div>
          <div className='mt-m danger mb-m'>
            <PrimaryButtonComponent
              buttonText="Decline Request"
              isDisabled={false}
              onClick={showModal}
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyles.grey_outline}

            />
          </div>
        </div>
        {/* <div className='mobile-logo-wrap'>
          <img src={BetterIDLogo} className='logo-mobile' alt="Better ID Logo" />
        </div> */}
      </Card>)}
      <ModalTemplate title="" visible={isModalVisible} onClose={handleCancel}>
        <div className='mb-m'>

          <img src={Warning} alt="Warning" />

        </div>
        <div className="verification-process">
          <h4 className='Text-Primary mb-xl'>Are you sure you want to decline this request?</h4>
          {/* <div className='verification-contents'>
            <h4 className='BaseRegular mb-xl'> Are you sure you want to decline this request?</h4>
          </div> */}
          <PrimaryButtonComponent
            buttonText="Yes, Decline"
            isDisabled={false}
            onClick={handleDecline}
            buttonSize={ButtonSizes.large}
            buttonStyle={ButtonStyles.solid}
          />
          <div className='mt-m'>
            <PrimaryButtonComponent
              buttonText="Cancel"
              isDisabled={false}
              onClick={handleCancel}
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyles.grey_outline}
            />
          </div>
        </div>
      </ModalTemplate>
    </FlowTemplateComponent>


  );
};

export default AgentRequest;

function ButtonClick(logText: string, event: React.MouseEvent<HTMLButtonElement>) {

}

