import React from "react";
import NavigationRoutes from "./navigation/Navigation";
 import '@regulaforensics/vp-frontend-face-components';
import { LoadingProvider } from "./services/Utils/LoadingContext";
import "../src/assets/App.scss";
import { EventType } from "@azure/msal-browser";
import { b2cPolicies } from "./authConfig";
import {MsalProvider} from "@azure/msal-react";

class App extends React.Component<{ pca: any }> {
    render() {
        let {pca} = this.props;
        return (
            <MsalProvider instance={pca}>
                <React.Fragment>

                    <LoadingProvider>
                        <NavigationRoutes/>
                    </LoadingProvider>
                </React.Fragment>
            </MsalProvider>
        );
    }
}

export default App;
