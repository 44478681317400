import React, { useEffect, useState,useContext } from 'react';
import { Card } from 'antd';
import { ButtonSizes, PrimaryButtonComponent, ButtonStyles, TextInputComponent } from "@betterhome/components";
import BetterIDLogo from '../../../assets/Icons/betterid.svg';
import FlowTemplateComponent from '../../../Components/Templpate/FlowTemplate';
import { useLocation, useNavigate } from 'react-router-dom';
import Text from "../IdentityVerification/Constants.json"
import { DataContext } from "../../../services/Utils/DataContext";
import { verifyPersonByFaceApi } from "../../../services/apiService/api";
import {useLoading} from "../../../services/Utils/LoadingContext";


interface IDVStatusProps { }

const IDVStatusComponent: React.FC<IDVStatusProps> = () => {
    const location = useLocation()
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

    const { isLoading, setLoading } = useLoading();
    const navigate = useNavigate()
    const [error, setError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(true);
    const [reloadKey, setReloadKey] = useState(0);
    const [idNumber1, setIdnumber1] = useState(null);
    const [disabled, setDisabled] = useState(false);

    const IDVStatusText = Text.IDVStatus
   let          imageData= sessionStorage.getItem("idvImage")??"";

    useEffect(() => {
        const storedProfile = sessionStorage.getItem("userDataSession");
        if (storedProfile) {
            const userDataObj = JSON.parse(storedProfile);
            const SaId = userDataObj.SaId || userDataObj.idNumber;
            const updateduserData = {
                ...userDataObj,
                image: ""    
            };
            sessionStorage.setItem("userDataSession", JSON.stringify(updateduserData));
           setIdnumber1(SaId);
        }
    }, [reloadKey]);
    
    useEffect(() => {
        const hasReloadedBefore = sessionStorage.getItem('hasReloaded');
        if (!hasReloadedBefore) {
            window.location.reload();
            sessionStorage.setItem('hasReloaded', 'true');
        }
    }, [reloadKey]);

    const idNumber = idNumber1;

    const onRetakePhotoClick = () => {
        sessionStorage.removeItem('hasReloaded'); // Remove the flag when retaking photo
        navigate(-1); // Go back to retake photo
    }
    
    window.onpopstate = function(event) {
        if (sessionStorage.getItem('hasReloaded')) {
            sessionStorage.removeItem('hasReloaded');
        }
    };

   /* const onContinueBtnClick = () => {
        navigate("/loading", { "state": { "image": "" } })
    }*/


    const onContinueBtnClick = () => {

        const idNumber = idNumber1;

        const fetchData = async (idNumber: string, imageData: string) => {
            if (!idNumber) {
                console.error("ID number is undefined.");
                return; // Return early if ID number is undefined
            }

            if (imageData) {
                try {
                    let sendImgData = imageData.replace("data:image/jpeg;base64, ","");
                    const result = await verifyPersonByFaceApi(idNumber, sendImgData);
                    sessionStorage.setItem("idvResponseData",JSON.stringify(result));
                    // Check if response is successful and contains data
                    if (result && result.value?.status == "Success") {
                        navigate("/idv-success", { "state": { "image": "image" } })
                    } else {
                        navigate("/idv-failed", { "state": { "image": "image" } })
                    }
                } catch (error) {
                }
            }
        };
        if (idNumber && imageData) {
            setDisabled(true);
            setLoading(true);
            fetchData(idNumber, imageData).then(res=>{
                setDisabled(false);
                setLoading(false);
            });
        }
    }

    return (
        <FlowTemplateComponent isLoggedin={isAuthenticated}>
            <Card className="page-card centered-content">
                <div className={`profile-container mb-xl ${error ? 'error' : ''}`}>
                    {error && <div className="error-border oval-profile-image"></div>}
                    <img src={imageData ?? ""} className={`oval-profile-image ${error ? 'error' : ''}`} alt="Profile Image" />
                </div>
                <div className="verification-process">
                    <h3 className='Text-Primary mb-m'>{IDVStatusText.selfieTitle}</h3>
                    <div className='verification-contents mb-xl'>
                        <h5 className='BaseRegular'>{IDVStatusText.selfieSubtitle}</h5>
                    </div>
                    <PrimaryButtonComponent
                        buttonText={IDVStatusText.btnTextContinue}
                        //isDisabled={disabled}
                        isLoading={isLoading}
                        onClick={onContinueBtnClick}
                        buttonSize={ButtonSizes.large}
                        buttonStyle={ButtonStyles.solid}
                    />
                    <div className='mt-m'>
                    <PrimaryButtonComponent
                        buttonText={IDVStatusText.btnTextRetake}
                        //isDisabled={false}
                        onClick={onRetakePhotoClick}
                        buttonSize={ButtonSizes.large}
                        buttonStyle={ButtonStyles.transparent}
                    />
                    </div>
                </div>
            </Card>
        </FlowTemplateComponent>
    );
};

export default IDVStatusComponent;