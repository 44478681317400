import service from "../networkService/Api";

export async function CustomerRequests(input: string) {
    try {
        const response = await service.get(`api/CustomerRequests/CustomerMobile/${input}`);
        return response;
    } catch (error:any) {
        return {
            status: "error",
            message: error.message
        };
    }
}

export async function getCustomerRequestByCustometId(input: string){
    try {
        const response = await service.get(`api/CustomerRequests/${input}`);
        return response;
    } catch (error:any) {
        return {
            status: "error",
            message: error.message
        };
    }
}

export async function verifyPersonByFaceApi(idNumber:string, imageData:string) {
    try {
        const response = await service.post(`api/PersonVerification/VerifyPersonByFace`, {
            idNumber: idNumber,
            biometric: imageData
        });
        if (response.status === 200) {
            return response.data;
        }
        return null;
    } catch (error:any) {
        return {
            status: "error",
            message: error.message
        };
    }
}

export async function updateCustomerRequestStatusAPI(status :string, requestId:any) {
    try {
        const response = await service.put(`api/CustomerRequests/UpdateCRStatus/${requestId}/${status}`);
        if (response.status === 204) {
            return response.data;
        }
        return null;
    } catch (error:any) {
        return {
            status: "error",
            message: error.message
        };
    }
}

export async function updateCustomerRequestFeatureStatusAPI(status :string, requestId:any) {
    try {
        const response = await service.put(`api/CustomerRequests/UpdateRFStatus/${requestId}/${status}`);
        if (response.status === 204) {
            return true;
        }
        return null;
    } catch (error:any) {
        return {
            status: "error",
            message: error.message
        };
    }
}

export async function getCustomerRequestStatusAPI(){
    try{
        const response = await service.get(`api/Lookups/CustomerRequestStatus`)
        if(response.status === 200){
            return response.data;
        }
        return null
    }catch( error: any){
        return{
            status: "error",
            message: error.message
        }
    }
}

export async function getCustomerRequestFeatureTypeAPI(){
    try{
        const response = await service.get(`api/Lookups/RequestedFeatureTypes`)
        if(response.status === 200){
            return response.data;
        }
        return null
    }catch( error: any){
        return{
            status: "error",
            message: error.message
        }
    }
}

export async function getPeopleIdentityNumberApi(input: string){
    try {
        const response = await service.get(`api/People/IdentityNumber?IdentityNumber=${input}`);
        return response;
    } catch (error:any) {
        return {
            status: "error",
            message: error.message
        };
    }
}

export async function getPeopleMobileApi(input: string){
    try {
        const response = await service.get(`api/People/MobileNumber?CustomerMobile=${input}`);
        return response;
    } catch (error:any) {
        return {
            status: "error",
            message: error.message
        };
    }
}

export async function getPeopleSourceSystemIDApi(input: string){
    try {
        const response = await service.get(`api/People/SourceSystemId?SourceSystemId=${input}`);
        return response;
    } catch (error:any) {
        return {
            status: "error",
            message: error.message
        };
    }
}

export interface PeoplePostData {
    idNumber: string;
    sourceSystemId:string;
    givenName: string;
    familyName: string;
    identityDocumentTypeId: string;
    isVerified: boolean;
    phoneNumber: string;
    phoneNumberTypeId: string;
    emailAddress: string;
    emailAddressTypeId: string;
}

export async function peopleCreateApi(data: PeoplePostData) {
    const {
        idNumber,
        sourceSystemId,
        givenName,
        familyName,
        identityDocumentTypeId,
        isVerified,
        phoneNumber,
        phoneNumberTypeId,  
        emailAddress,
        emailAddressTypeId
    } = data;
    try {
        const payload = {
            name: {
                familyName,
                givenName,
            },
            sourceSystemTypeId: "2",
            sourceSystemId:sourceSystemId,
            identityDocument: {
                identityDocumentTypeId,
                identityNumber: idNumber,
                isVerified
            },
            phoneNumbers: [
                {
                    value: phoneNumber,
                    phoneNumberTypeId
                }
            ],
            emailAddresses: [
                {
                    value: emailAddress,
                    emailAddressTypeId
                }
            ]
        };
        const response = await service.post('api/People', payload);
        if (response.status === 201) {
            return {"resdata" : response.data,"status":"Success"};
        } else {
            return {
                status: "error",
                message: `Unexpected response status: ${response.status}`
            };
        }
    } catch (error: any) {  
        return {
            status: "error",
            message: error.message
        };
    }
}

export async function sendConsentApi(personId:string) {
    try {
        const response = await service.post(`api/Consent`, {
            personId: personId,
            consents: [
                {
                    sourceURN: process.env.REACT_APP_SOURCE_URN,
                    apiClientId: process.env.REACT_APP_API_CLIENT_ID,
                    createdBy: process.env.REACT_APP_CREATED_BY,
                    consentTypeId: process.env.REACT_APP_CONSENT_TYPE_ID,
                    granted: true
                }
            ]
        });
        if (response.status === 201) {
            return response.status;
        }
        return null;
    } catch (error:any) {
        return {
            status: "error",
            message: error.message
        };
    }
}

export async function getSecurityQuestionsAPI(personId:string){
    try{
        //const response = await service.post(`api/QuestionBasedAuth/RequestQuestions?PersonID=${personId}`);
        //Remove after api integration
        const response = {
            "response_status": "Success",
            "error_code": null,
            "error_description": null,
            "return_data": {
                "transaction_id": 8394331863274654000,
                "status_code": "TSP",
                "status_code_description": "Transaction Status Pending",
                "knock_out_rule_triggered": null,
                "question_count": 5,
                "question_data": [
                {
                    "question_number": 1,
                    "question_text": "Which home telephone number belongs/belonged to you?",
                    "option_count": 5,
                    "option_data": [
                    {
                        "option_number": 1,
                        "optionText": "0419334247"
                    },
                    {
                        "option_number": 2,
                        "optionText": "0119999999"
                    },
                    {
                        "option_number": 3,
                        "optionText": "0123477293"
                    },
                    {
                        "option_number": 4,
                        "optionText": "ALL OF THE ABOVE"
                    },
                    {
                        "option_number": 5,
                        "optionText": "NONE OF THE ABOVE"
                    }
                    ]
                },
                {
                    "question_number": 2,
                    "question_text": "In which suburb did you purchase you last property?",
                    "option_count": 4,
                    "option_data": [
                    {
                        "option_number": 1,
                        "optionText": "JAKKALSF"
                    },
                    {
                        "option_number": 2,
                        "optionText": "FAIRWAYS"
                    },
                    {
                        "option_number": 3,
                        "optionText": "OLIVEDALE EXT"
                    },
                    {
                        "option_number": 4,
                        "optionText": "NONE OF THE ABOVE"
                    }
                    ]
                },
                {
                    "question_number": 3,
                    "question_text": "Which of the following companies have you been employed at?",
                    "option_count": 5,
                    "option_data": [
                    {
                        "option_number": 1,
                        "optionText": "BETTERLIFE GROUP LIMITED"
                    },
                    {
                        "option_number": 2,
                        "optionText": "ITQ BUSINESS SOLUTIONS"
                    },
                    {
                        "option_number": 3,
                        "optionText": "BETTERLIFE"
                    },
                    {
                        "option_number": 4,
                        "optionText": "ALL OF THE ABOVE"
                    },
                    {
                        "option_number": 5,
                        "optionText": "NONE OF THE ABOVE"
                    }
                    ]
                },
                {
                    "question_number": 4,
                    "question_text": "Which service provider approved your open home loan?",
                    "option_count": 5,
                    "option_data": [
                    {
                        "option_number": 1,
                        "optionText": "NEDBANK - MORTGAGE LOANS"
                    },
                    {
                        "option_number": 2,
                        "optionText": "SA HOME LOANS"
                    },
                    {
                        "option_number": 3,
                        "optionText": "AGATTU TRADING 191 (PTY) LTD"
                    },
                    {
                        "option_number": 4,
                        "optionText": "ALL OF THE ABOVE"
                    },
                    {
                        "option_number": 5,
                        "optionText": "NONE OF THE ABOVE"
                    }
                    ]
                }
                ]
            }
        } 
         return response
        //Uncomment after api integration
        // if(response.status === 200){
        //     return response.data;
        // }
    } catch( error: any){
        return{
            status: "error",
            message: error.message
        }
    }
}



export async function postSubmitAnswer(submitData: any) {
    try {
        const response = await service.post('api/QuestionBasedAuth/SubmitResult', submitData);
        //if (response.status === 200 && response.data.return_data != null) {
        if (response.status === 200) {
            return { resdata: response.data, status: "Success" };
        } else {
            return {
                status: "error",
                message: "Something went wrong"
            };
        }
    } catch (error: any) {
        return {
            status: "error",
            message: error.message
        };
    }
}

export async function getPersonFromPersonIdApi(personId:any){
    try{
        const response = await service.get(`api/People/${personId}`)
        if(response.status === 200){
            return response.data;
        }
        return null
    }catch( error: any){
        return{
            status: "error",
            message: error.message
        }
    }
}


export async function verifyAddress(verifyAddressData: any) {
    try {
        const response = await service.post('api/PersonVerification/VerifyAddress', verifyAddressData);
        if (response.status === 200) {
            return { resdata: response.data, status: "Success" };
        } else {
            return {
                status: "error",
                message: "Something went wrong"
            };
        }
    } catch (error: any) {
        return {
            status: "error",
            message: error.message
        };
    }
}

export async function CheckUserExists(userId: string,) {
    try {
        const response = await service.get(`api/CustomerRequests/CustomerMobile/${userId}`);
        return response;
    } catch (error:any) {
        return {
            status: "error",
            message: error.message
        };
    }
}