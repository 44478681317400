import React, { FunctionComponent, useEffect, useState } from 'react';
import { Card } from 'antd';
import { ButtonSizes, PrimaryButtonComponent, ButtonStyles } from "@betterhome/components";
import Warning from '../../../assets/Icons/warning.svg';
import CentredTemplateComponent from '../../../Components/Templpate/CentredTemplateComponent';
import { useNavigate,Link, useLocation } from 'react-router-dom';
import Text from "../IdentityVerification/Constants.json"
interface WrongAnswerComponentProps {

}

const WrongAnswerComponent: FunctionComponent<WrongAnswerComponentProps> = () => {
    const WrongAnswerText = Text.WrongAnswer
    const navigate = useNavigate()
    const location = useLocation()
    const error = location.state.error
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    const [isMaxAttemps, setIsMaxAttemps] = useState(false)

    useEffect(() => {
      if(error === "maxAttempts"){
        setIsMaxAttemps(true)
      }
  }, [error]);

   
    const handleTryAgain = () => {
      isMaxAttemps ? navigate("/dashboard") : navigate("/identity-questions")
    }
    const handleTrySelfie = () => {
        navigate("/idv-start")
    }
    return (
      <CentredTemplateComponent isLoggedin={isAuthenticated}>
        <Card className="page-card centered-content">
          <div className="mb-m">
            <img src={Warning} alt="Warning" />
          </div>
          <div className="verification-process">
            <div className="verification-contents">
              <h4 className="Text-Primary mb-xs">{WrongAnswerText.tittle}</h4>
              <h4 className="BaseRegular mb-xl">
                {" "}
                {isMaxAttemps ? WrongAnswerText.subTittle1 : WrongAnswerText.subTittle}
              </h4>
            </div>

            <PrimaryButtonComponent
              buttonText={isMaxAttemps ? WrongAnswerText.btnText3 : WrongAnswerText.btnText1}
              isDisabled={false}
              onClick={handleTryAgain}
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyles.solid}
            />

            <div className="mt-xl">
              <Link
                to="/idv-start"
                className="BaseRegular linktext"
                onClick={handleTrySelfie}
              >
                {WrongAnswerText.btnText2}
              </Link>
            </div>
          </div>
        </Card>
      </CentredTemplateComponent>
    );
}

export default WrongAnswerComponent;