import React, { useState, useEffect } from 'react';
import { Card, Divider } from 'antd';
import { PrimaryButtonComponent, ButtonSizes, ButtonStyles, Search } from "@betterhome/components";
import FlowTemplateComponent from '../../Components/Templpate/FlowTemplate';
import { MapPin } from "@phosphor-icons/react";
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { useNavigate } from 'react-router-dom';
import Text from "./Constants.json";

const POASearchAddressComponent: React.FC = () => {
  const userAddMain = sessionStorage.getItem("userAddMain") ?? "";
  const [address, setAddress] = useState(userAddMain);
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  const POASearchAddressComponentText = Text.POASearchAddressComponent;

  useEffect(() => {
    if (userAddMain) {
      setAddress(userAddMain);
      // Navigate after 2 seconds if userAddMain has a value
      const timeout = setTimeout(() => {
        navigate("/poa-manual", { state: { address: userAddMain } });
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [userAddMain, navigate]);

  const btnManualsClicked = () => {
    sessionStorage.setItem("userAddMain","");
    sessionStorage.setItem("userAddress","");
    navigate("/poa-manual");
  };

  const handleAddressChange = (value: string) => {
    setAddress(value);
  };

  const handleSelect = async (event: any) => {
    setAddress(event);
    try {
      const results = await geocodeByAddress(event);
      const addressComponents = results[0].address_components;

      let addressLine1 = '';
      let addressLine2 = '';
      let suburb = '';
      let postalCode = '';
      addressComponents.forEach(component => {
        if (component.types.includes('street_number')) {
          addressLine1 = addressLine1 = `${component.long_name} ${addressLine1}`;
        }
        if (component.types.includes('route')) {
          addressLine1 += component.long_name;
        }
        if (component.types.includes('sublocality') || component.types.includes('neighborhood')) {
          addressLine2 = component.long_name;
        }
        if (component.types.includes('locality')) {
          suburb = component.long_name;
        }
        if (component.types.includes('postal_code')) {
          postalCode = component.long_name;
        }
      });
      // Create JSON object
      const addressJSON = {
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        suburb: suburb,
        postalCode: postalCode
      };
      sessionStorage.setItem("userAddress", JSON.stringify(addressJSON));
      navigate("/poa-manual");
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  const searchOptions = {
    componentRestrictions: { country: 'za' }
  };

  return (
    <div className='poa-update'>
      <FlowTemplateComponent hideLogo={true} isLoggedin={isAuthenticated}>
        <Card className="page-card centered-content">
          <div className="verification-process">
            <div className="mb-xl">
              <PlacesAutocomplete
                value={address}
                onChange={handleAddressChange}
                onSelect={handleSelect}
                searchOptions={searchOptions}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <div className='desktopshow'>
                      <Search
                        {...getInputProps({
                          placeholder: "Search address",
                          searchType: "",
                        })}
                        searchedAddress={address}
                      />
                    </div>
                    <div className='mobileshow'>
                      <div className='top-search poa-update'>
                        <Search
                          {...getInputProps({
                          placeholder: "Search address",
                          searchType: "inline-search",
                          })}
                          searchedAddress={address}
                        />
                      </div>
                      <div className='poa-search-divider' />
                    </div>
                    {suggestions.length > 0 && (
                      <div className="transparent-card mt-s dropdown-address-card">
                        {loading && <div>{POASearchAddressComponentText.textLoading}</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "dropdown-address-selected"
                            : "dropdown-address";

                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                              })}
                            >
                              <MapPin className='map-icon' weight="regular" size={24} />
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            <div className='gray-btn'>
              <PrimaryButtonComponent
                buttonText={POASearchAddressComponentText.btnText}
                isDisabled={false}
                onClick={btnManualsClicked}
                buttonSize={ButtonSizes.medium}
                buttonStyle={ButtonStyles.solid}
              />
            </div>
          </div>
        </Card>
      </FlowTemplateComponent>
    </div>
  );
};

export default POASearchAddressComponent;
