import React, { useState } from 'react';
import { Layout, Menu, Dropdown, Button } from 'antd';
import { List, X } from "@phosphor-icons/react";
import { ButtonSizes, PrimaryButtonComponent, ButtonStyles } from "@betterhome/components";
import BetterIDLogo from '../../assets/Icons/betterid.svg';
import Help from '../../assets/Icons/help.svg';
import Terms from '../../assets/Icons/terms.svg';
import Privacy from '../../assets/Icons/privacy.svg';

const { Header, Content } = Layout;

const BeforeLoginHeaderComponent = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = window.innerWidth <= 768;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleDropdownVisibleChange = (visible: boolean) => {
    setIsMenuOpen(visible);
  };

  // const userMenu = (
  //   <Menu>
  //     <Menu.Item key="help">
  //       <PrimaryButtonComponent
  //         buttonText="Need help?"
  //         isDisabled={false}
  //         onClick={(event) => ButtonClick("Need help?", event)}
  //         buttonSize={ButtonSizes.medium}
  //         buttonStyle={ButtonStyles.outline}
  //       />
  //     </Menu.Item>
  //   </Menu>
  // );

  const menu = (
    <div className="menudropdownds">
      <Menu>
        <Menu.Item key="0" className='mobileshow'><div className='alignmenuitem'><img src={BetterIDLogo} alt="Better ID Logo" /> <span className='closebg'><X color="var(--text-color-dynamic)" weight="regular" size={24} /></span></div></Menu.Item>
        <Menu.Item key="2"><span className='menuitem'><img src={Help} alt="Help" /><span className='menulabel'>Help</span></span></Menu.Item>
        <Menu.Item key="3"><span className='menuitem'><img src={Privacy} alt="Privacy" /><span className='menulabel'>Terms of Use</span></span></Menu.Item>
        <Menu.Item key="4"><span className='menuitem'><img src={Terms} alt="Terms" /><span className='menulabel'>Privacy Policy</span></span></Menu.Item>
      </Menu>
    </div>
  );

  return (
    <Layout>
      <Header className="mobile-header">
        <div className="default-header">
          <div className=""><img src={BetterIDLogo} alt="Better ID Logo" /></div>
          <div className="menudropdown">
            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomRight"
              open={isMenuOpen}
              onOpenChange={handleDropdownVisibleChange}
              overlayClassName='customdropdown'
            >
              <Button type='link' className='hamburger' style={{ padding: '0px', margin: '0px' }}>
                <List color="var(--text-color-dynamic)" weight="regular" size={24} />
              </Button>
            </Dropdown>
          </div>
        </div>
      </Header>

      <Header className="desktop-header">
        <div className="logo"><img src={BetterIDLogo} alt="Better ID Logo" /></div>
        <div className="header-icons">
        
        <PrimaryButtonComponent
                buttonText="Need help?"
                isDisabled={false}
                onClick={(event) => ButtonClick("Need help?", event)}
                buttonSize={ButtonSizes.medium}
                buttonStyle={ButtonStyles.outline}
              />
         
        </div>
      </Header>


    </Layout>
  );
};

export default BeforeLoginHeaderComponent;

function ButtonClick(logText: string, event: React.MouseEvent<HTMLButtonElement>) {
  // console.log(logText + " clicked", event);
}
