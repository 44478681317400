import React from 'react';
import { Card } from 'antd';
import { ButtonSizes, PrimaryButtonComponent, ButtonStyles } from "@betterhome/components";
import CentredTemplateComponent from '../../../Components/Templpate/CentredTemplateComponent';
import chat from '../../../assets/Icons/chat.svg';
import { useNavigate } from 'react-router-dom';
import Text from "../IdentityVerification/Constants.json"

interface SecurityQuestionProps {
}


const SecurityQuestionComponent: React.FC<SecurityQuestionProps> = () => {
  const SecurityQuestionComponentText = Text.SecurityQuestionComponent
  const navigate = useNavigate()
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const handleBtnClick = () => {
    navigate("/identity-questions")
 }
  
  return (
    <CentredTemplateComponent isLoggedin={isAuthenticated}>
      <Card className="page-card centered-content">
          {/* Card contents go here */}
          <div className='mb-m'>
            <img src={chat} alt="chat" />
          </div>
          <div className="verification-process">
          <h4 className='Text-Primary mb-xs'>{SecurityQuestionComponentText.tittle}</h4>
          <div className='verification-contents mb-xl'>
            <span className='BaseRegular'>{SecurityQuestionComponentText.subTittle}</span>
          </div>
        
          </div>
          <PrimaryButtonComponent
              buttonText={SecurityQuestionComponentText.btnTextContinue}
              isDisabled={false}
              onClick={handleBtnClick}
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyles.solid}
            />

        </Card>
      </CentredTemplateComponent>
  );
};

export default SecurityQuestionComponent;