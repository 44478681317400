import React from 'react';
import { Card } from 'antd';
import { ImageSquare, Eyeglasses, Sun, Camera, Smiley } from "@phosphor-icons/react";
import { ButtonSizes, PrimaryButtonComponent, ButtonStyles } from "@betterhome/components";
import CameraIcon from '../../../assets/Icons/camera.svg';
import CrosshairIcon from '../../../assets/Icons/cross-hair.svg';
import BetterIDLogo from '../../../assets/Icons/betterid.svg';
import DefaultTemplateComponent from '../../../Components/Templpate/DefaultTemplate';
import {useNavigate} from "react-router-dom";
import Text from "../IdentityVerification/Constants.json"


const IdentityVerificationComponent = () => {
  const IdentityVerificationText = Text.IdentityVerification
  interface IdentityVerificationComponentProps {
    icon: React.ReactNode;
    title: string;
  }

  const navigate = useNavigate()
  const btnContinueClicked = () => {
    navigate("/image-capture")
  }

  const VerificationStep: React.FC<IdentityVerificationComponentProps> = ({ icon, title }) => {
    return (
      <div className="verification-step mt-5 mb-5">
        <div className="step-icon">{icon}</div>
        <div className="BaseRegular">{title}</div>
      </div>
    );
  };


  return (
    <DefaultTemplateComponent>
      <Card className="page-card centered-content">
        <div className='mb-m'>

          <img src={CameraIcon} alt="Camera Icon" />

        </div>
        <div className="verification-process">

          <h4 className='Text-Primary'>{IdentityVerificationText.selfieTitle}</h4>
          <h4 className='BaseRegular vert-spacing-xl'> {IdentityVerificationText.selfieSubtitle}</h4>

          <VerificationStep
            icon={<Camera className='Text-Subtitle' weight="regular" size={20} />}
            title={IdentityVerificationText.subtitleInstruction1}
          />

          <VerificationStep
            icon={<Sun className='Text-Subtitle' weight="regular" size={20} />}
            title={IdentityVerificationText.subtitleInstruction2}
          />

          <VerificationStep
            icon={<ImageSquare className='Text-Subtitle' weight="regular" size={20} />}
            title={IdentityVerificationText.subtitleInstruction3}
          />

          <VerificationStep
            icon={<Eyeglasses className='Text-Subtitle' weight="regular" size={20} />}
            title={IdentityVerificationText.subtitleInstruction4}
          />
          <VerificationStep
            icon={<img src={CrosshairIcon} className='Text-Subtitle' alt="Crosshair Icon" />}
            title={IdentityVerificationText.subtitleInstruction5}
          />

          <VerificationStep
            icon={<Smiley className='Text-Subtitle' weight="regular" size={20} />}
            title={IdentityVerificationText.subtitleInstruction6}
          />

          <div className='mt-xxxl'>
            <PrimaryButtonComponent
              buttonText={IdentityVerificationText.btnTextContinue}
              isDisabled={false}
              onClick={(event) => { btnContinueClicked()}}
              buttonSize={ButtonSizes.large}
              buttonStyle={ButtonStyles.solid}

            />
          </div>
        </div>
      </Card>
      <img src={BetterIDLogo} className='logo-mobile' alt="Better ID Logo" />
    </DefaultTemplateComponent>

  );
};

export default IdentityVerificationComponent;
