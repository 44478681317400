import React from 'react';
import { Card } from 'antd';
import { BadgeComponent } from "@betterhome/components";

interface PreviousRequestCardProps {
  name: string;
  content: string;
  BadgeText: string;
  BadgeStatus:"Expired" | "Completed" | "Rejected" | "Pending" | "Accepted" | "New";
  image:string;
  companyLogo:string;
}

const PreviousRequestCardComponent: React.FC<PreviousRequestCardProps> = ({ name, companyLogo,image, content, BadgeText, BadgeStatus }) => {

  return (
    <Card className="request-card centered-content mb-s">
      <div className='request-card-content'>
        <div className='profile-section'>
          <div className='profile-container mb-l'>

           {image && <img src={image} className='agent-profile-image' alt="Agent Photo" />}
           {companyLogo && <img src={companyLogo} className={image ? 'agent-agency-logo': 'agent-agency-logo-single'} alt="Company Logo" />}

          </div>

        </div>

        <div className='request-badge'>
          <BadgeComponent text={BadgeText} status={BadgeStatus} />
        </div>
      </div>
      <div className="verification-process" style={{ textAlign: 'left' }}>
        <h5 className='Text-Primary'>{name}</h5>
        <h5 className='SmallRegular'>{content}</h5>
      </div>
    </Card>
  );
};

export default PreviousRequestCardComponent;
