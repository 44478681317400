import React from 'react';
import { Layout } from 'antd';
import BetterIdHeaderComponent from '../Headers/BetterIdHeaderComponent';

interface CentredTemplateProps {
  children?: React.ReactNode;
}

const { Content } = Layout;

const CentredTemplateSuccessComponent: React.FC<CentredTemplateProps> = ({ children }) => {


  return (
    <Layout>
      <Content className="page-wrapper-success">
        {children}
      </Content>
    </Layout>
  );
};

export default CentredTemplateSuccessComponent;
