import React from 'react';
import { Layout } from 'antd';
import BetterIDLogo from '../../assets/Icons/betterid.svg';
import BetterIdHeaderComponent from '../Headers/BetterIdHeaderComponent';


interface FlowTemplateProps {
    children?: React.ReactNode;
    hideLogo?: boolean;
    hideCaretLeft?: boolean;
    isLoggedin?: boolean;
}

const { Content } = Layout;

const FlowTemplateComponent: React.FC<FlowTemplateProps> =  ({ children, hideLogo = false, hideCaretLeft = false, isLoggedin}) => {
    return (
        <Layout>
            <BetterIdHeaderComponent isLoginProps={false} isRegisterProps={true} hideCaretLeft={hideCaretLeft} isLoggedin={isLoggedin} />
            <Content className="page-wrapper">
                <div className='w100'>
                    <div className='placeholder-section'>
                        {children}
                    </div>
                </div>
                {!hideLogo && (
                <div className='centered-logo'>
                <img src={BetterIDLogo} className='flow-logo-mobile'  alt="Better ID Logo" />
                </div>
                 )}
            </Content>
        </Layout>
    );
};

export default FlowTemplateComponent;