import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import './ModalTemplateComponent.scss'; 

interface ModalTemplateProps {
  title?: string;
  children?: React.ReactNode;
  visible: boolean; 
  onClose: () => void; 
}


const ModalTemplate: React.FC<ModalTemplateProps> = ({ title, children, visible, onClose}) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isVisible, setIsVisible] = useState(visible);
  useEffect(() => {
    if (visible) {
      setIsVisible(true);
      setIsClosing(false);
    }
  }, [visible]);

  const handleCloseButtonClick = () => {
    setIsClosing(true); 
    setTimeout(() => {
      setIsVisible(false); 
      onClose(); 
    }, 500); 
  };

 
  if (!isVisible && !isClosing) {
    return null;
  }
  return (
    <Modal
      title={title}
      visible={visible}
      footer={null}
      centered
      wrapClassName="custom-modal"
      onCancel={handleCloseButtonClick} 
      className={isClosing ? 'slide-down' : 'slide-up'}
    >
      {children}
    </Modal>
  );
};


export default ModalTemplate;
